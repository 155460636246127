import React, { Component } from 'react';
import './App.css';
import MainWindow from './components/MainWindow/MainWindow';

class App extends Component {
    render() {
        return (
            <div className={'App'}>
                <MainWindow location={this.props.location} />
            </div>
        );
    }
}

export default App;
