import React from 'react';
import { Col, Divider, Dropdown, Icon, Menu, Popover, Row } from 'antd';
import { Logo } from './Logo';
import Space from '../ComponentUI/Spcae';
import { ComponentButton } from '../ComponentUI/ComponentButton';
import { ComponentIcon } from '../ComponentUI/ComponentIcon';
import Images from '../../../images/images';
import { ComponentText } from '../ComponentUI/ComponentText';
import { fonts } from '../../../fonts/fonts';
import { Colors } from '../../../css/Colors';
import { paths } from '../Routes';
import { getDecreasingSpan, getIncreasingSpan, getTopBarSpace } from '../Col4';

const { SubMenu } = Menu;

const menus = [
    {
        name: 'Products',
        path: '/products',
        subMenu: [
            {
                icon: Images.subscriptionManagement,
                title: 'Subscription Management',
                description: 'Manage subscriptions at scale',
                path: paths.SubscriptionManagement,
            },
            {
                icon: Images.plansManagement,
                title: 'Plans Management',
                description: 'Plan & product management simplified',
                path: paths.PlansProduct,
            },
            {
                icon: Images.customerProfile,
                title: 'Customer Profile',
                description: 'Know your customers',
                path: paths.CustomerProfile,
            },
            {
                icon: Images.charting,
                title: 'Charting & Metrics',
                description: 'Analytics to know the real story',
                path: paths.ChartsMetrics,
            },
        ],
    },
    {
        name: 'Pricing',
        path: paths.PricingPlans,
        subMenu: [],
    },
    {
        name: 'Resources',
        path: '/resources',
        subMenu: [],
    },
    {
        name: 'Company',
        path: '/about-us',
        subMenu: [],
    },
];
export class TopBar extends React.Component {
    content = () => {
        return (
            <div>
                <Menu style={{ width: 256 }} defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} mode="inline">
                    <Divider />
                    <SubMenu key="product" title="Product">
                        <Menu.Item key="2">Product 1</Menu.Item>
                        <Menu.Item key="3">Product 2</Menu.Item>
                    </SubMenu>
                    <Divider />
                    <Menu.Item key="pricing">
                        {' '}
                        <a href={paths.PricingPlans}>Pricing</a>
                    </Menu.Item>
                    <Divider />
                    <Menu.Item key="blog">
                        {' '}
                        <a href={paths.Blog}>Blog</a>
                    </Menu.Item>
                    <Divider />
                    <Menu.Item key="documentation">
                        {' '}
                        <a href={paths.Documentation}> Documentation</a>
                    </Menu.Item>
                    <Divider />
                    <Menu.Item key="about">
                        {' '}
                        <a href={paths.AboutUs}>About</a>
                    </Menu.Item>
                    <Divider />
                    <Menu.Item key="login">
                        {' '}
                        <a href={paths.Login}> Login</a>
                    </Menu.Item>
                    <Divider />
                    <Space height={22} />
                    <Row type={'flex'}>
                        <a href={paths.SignUp}>
                            <ComponentButton
                                text={'Sign Up'}
                                css={'deep-sky-blue-and-white-menu'}
                                iconRight={'arrow-right-white'}
                                height={50}
                                width={124}
                            />
                        </a>
                        <Space width={16} />
                        <a href={paths.LiveDemo}>
                            <ComponentButton
                                text={'Live Demo'}
                                css={'white-and-deep-sky-blue-menu'}
                                iconRight={'arrow-right'}
                                height={50}
                                width={145}
                            />
                        </a>
                    </Row>
                    <Space height={68} />
                </Menu>
            </div>
        );
    };
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400, visible: false };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;

        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareMenu = (subMenu) => {
        return (
            <Menu>
                {subMenu.map((e, i) => (
                    <Menu.Item key={i}>
                        <a href={e.path}>
                            {' '}
                            <Row type={'flex'} align={'middle'} className={'top-bar-menu-item'}>
                                <Col>
                                    <img src={e.icon} width={30} alt={''} />
                                </Col>
                                <Col>
                                    <Space width={20} />
                                </Col>
                                <Col>
                                    <ComponentText
                                        text={e.title}
                                        align="left"
                                        font={fonts.SFProDisplayMedium}
                                        color={Colors.charcoal_grey}
                                        size={16}
                                    />
                                    <ComponentText
                                        text={e.description}
                                        align="left"
                                        font={fonts.SFProDisplayMedium}
                                        color={Colors.cool_grey_three}
                                        size={14}
                                    />
                                </Col>
                            </Row>
                        </a>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    prepareView = () => {
        return (
            <Row type={'flex'}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />

                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Space height={30} />
                    <Row type="flex" justify="space-between" align="middle" style={{ height: 45 }}>
                        <Row type="flex" justify="start" align="middle" style={{ height: 45 }}>
                            <Logo />
                            <Space width={18} />
                            {menus.map((e, index) => (
                                <Row key={e.name} type="flex" justify="start" align="middle">
                                    <Space width={getTopBarSpace(this.state.screenWidth)} />
                                    {e.subMenu.length > 0 ? (
                                        <Dropdown key={index} overlay={this.prepareMenu(e.subMenu)}>
                                            <a key={index} href={e.path}>
                                                <span className="top-bar-link">
                                                    {e.name}
                                                    {e.subMenu.length > 0 && (
                                                        <ComponentIcon antd={true} type={'caret-down'} />
                                                    )}
                                                </span>
                                            </a>
                                        </Dropdown>
                                    ) : (
                                        <a key={index} href={e.path}>
                                            <span className="top-bar-link">{e.name}</span>
                                        </a>
                                    )}
                                    <Space width={getTopBarSpace(this.state.screenWidth)} />
                                </Row>
                            ))}
                        </Row>
                        <Row type="flex" justify="start" align="middle">
                            <a href={'/login'}>
                                <span className="top-bar-link">{'Login'}</span>
                            </a>
                            <Space width={20} />
                            <ComponentButton text={'Sign Up'} css={'deep-sky-blue-and-white'} height={40} width={100} />
                        </Row>
                    </Row>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    };

    prepareMobileView = () => {
        return (
            <Row>
                <Row type={'flex'} justify={'space-between'} align={'middle'} style={{ padding: 20 }}>
                    <Logo />
                    <Popover
                        placement="bottomRight"
                        content={this.content()}
                        trigger="click"
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                        {this.state.visible ? (
                            <a onClick={this.hide}>
                                <ComponentIcon type={'close'} />
                            </a>
                        ) : (
                            <Icon type={'menu'} />
                        )}
                    </Popover>
                </Row>
            </Row>
        );
    };
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
}
