import React from 'react';
import { Col, Row } from 'antd';
import './AboutUs.css';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Space from '../Shared/ComponentUI/Spcae';
import { Footer } from '../Shared/Menus/Footer';
import { TopBar } from '../Shared/Menus/TopBar';
import Images from '../../images/images';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { TrustedCustomer} from "../Home/TrustedCustomer";

const contents = [
    {
        subject: 'Meet Purchasefly',
        title: 'Simplify In-App Subscription',
        description:
            'To help app developers everywhere earn with their apps. Building a platform to help developers make a living doing what they love.',
        marginBottom: 80,
        marginBottomMobile: 60,
        align: 'center',
    },
    {
        image: Images.aboutUs,
        marginBottom: 80,
        marginBottomMobile: 60,
        align: 'center',
    },
    {
        subject: 'Our solution',
        title: 'An end-to-end platform',
        description:
            'From getting started to millions of users, we have a plan for you. Spread your wings with our enterprise-class mobile subscription management API and solutions.',
        marginBottom: 130,
        marginBottomMobile: 90,
        align: 'center',
    },
    {
        subject: 'Our Story',
        title: 'How we started',
        description:
            'Purchasefly was founded in 2021 by two entrepreneurs (and later, a third), each having different sized businesses, and all having less-than-perfect experiences getting paid.',
        marginBottom: 0,
        marginBottomMobile: 0,
        align: 'left',
    },

    {
        subject: '',
        title: '',
        description:
            'The creaky old payments systems available at the time had not kept up with the way they, or most people, do business online, on mobile, or in-store. Commerce was complex, confusing, and not flexible enough to adapt to their evolving needs.',
        marginBottom: 0,
        marginBottomMobile: 0,
        align: 'left',
    },
    {
        subject: '',
        title: '',
        description:
            'By offering a way to accept and disburse payments via any channel, and a single source of truth to manage it all, Assembly gives businesses the building blocks to compete, scale and thrive in an increasingly complex world.',
        marginBottom: 0,
        marginBottomMobile: 0,
        align: 'left',
    },
];

export class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <div className="about-us">
                <TopBar />
                <Space height={170} />
                <Row type="flex">
                    <Col span={2} />
                    <Col span={20}>
                        {contents.map((e, i) => {
                            return <PrepareContent key={i} content={e} />;
                        })}
                    </Col>
                    <Col span={2} />
                </Row>
                <Space height={150} />

                <div className={'founders'}>
                    <ComponentText
                        text="Founders"
                        align="center"
                        font={fonts.SFProDisplaySemiBold}
                        size={60}
                        color={Colors.black}
                    />
                    <Space height={60} />
                    <Row type="flex" className={"columns"}>
                        <Col span={4} />
                        <Col span={5} className={"founder"}>
                            <img src={Images.hasan} width={270} height={262}/>
                            <section>
                                <h2>MD Ahad Hasan</h2>
                                <a href="https://www.linkedin.com/in/hasanrafi/" target="_blank"><img src={Images.about_linkedin} width={30} height={30}/></a>
                            </section>
                        </Col>
                        <Col span={5} className={"founder"}>
                            <img src={Images.sanjid} width={270} height={262}/>
                            <section>
                                <h2>Sanjidul Haque</h2>
                                <a href="https://www.linkedin.com/in/sanjidulhoque/" target="_blank"><img src={Images.about_linkedin} width={30} height={30}/></a>
                            </section>
                        </Col>
                        <Col span={5} className={"founder"}>
                            <img src={Images.talha} width={270} height={262}/>
                            <section>
                                <h2>MD Habibur Rahman</h2>
                                <a href="https://www.linkedin.com/in/habiburrahmantalha/" target="_blank"><img src={Images.about_linkedin} width={30} height={30}/></a>
                            </section>
                        </Col>
                        <Col span={4} />
                    </Row>
                </div>
                <TrustedCustomer/>
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
    prepareMobileView() {
        return (
            <div className="about-us">
                <TopBar />

                <Space height={40} />
                <Row type="flex">
                    <Col span={2} />
                    <Col span={20}>
                        {contents.map((e, i) => {
                            return <PrepareContentMobile key={i} content={e} />;
                        })}
                    </Col>
                    <Col span={2} />
                </Row>
                <Space height={90} />
                <TrustedCustomer/>
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}

function PrepareContent({ content }) {
    return content.image ? (
        <img alt="" width="90%" src={content.image} />
    ) : (
        <Row type="flex" justify="center" style={{ marginBottom: content.marginBottom }}>
            <Col span={4} />
            <Col span={16}>
                <ComponentText
                    text={content.subject}
                    align="center"
                    font={fonts.SFProDisplayRegular}
                    size={22}
                    color={Colors.deep_sky_blue}
                />
                <Space height={24} />
                {content.title && (
                    <div>
                        <ComponentText
                            text={content.title}
                            align="center"
                            font={fonts.SFProDisplaySemiBold}
                            size={60}
                            color={Colors.black}
                        />
                        <Space height={50} />
                    </div>
                )}

                <ComponentText
                    text={content.description}
                    align={content.align}
                    font={fonts.SFProDisplayRegular}
                    size={22}
                    color={Colors.slate_grey}
                />
            </Col>
            <Col span={4} />
        </Row>
    );
}

function PrepareContentMobile({ content }) {
    return content.image ? (
        <img alt="" width="100%" src={content.image} style={{ marginBottom: content.marginBottom }} />
    ) : (
        <Row style={{ marginBottom: content.marginBottom }}>
            <ComponentText
                text={content.subject}
                align="center"
                font={fonts.SFProDisplayRegular}
                size={18}
                color={Colors.deep_sky_blue}
            />
            <Space height={24} />
            {content.title && (
                <div>
                    <ComponentText
                        text={content.title}
                        align="center"
                        font={fonts.SFProDisplaySemiBold}
                        size={32}
                        color={Colors.black}
                    />
                    <Space height={20} />
                </div>
            )}

            <ComponentText
                text={content.description}
                align={content.align}
                font={fonts.SFProDisplayRegular}
                size={18}
                color={Colors.slate_grey}
            />
        </Row>
    );
}
