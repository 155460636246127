import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from './ComponentUI/ComponentText';
import Space from './ComponentUI/Spcae';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { ComponentIcon } from './ComponentUI/ComponentIcon';
import { FeatureButton } from './FeatureButton';
import { FeatureButtonMobile } from './FeatureButtonMobile';
import { getDecreasingSpan, getIncreasingSpan } from './Col4';

const featuresPurchaseFly = [
    {
        icon: Images.subscriptionManagement,
        title: 'Subscription Management',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
        to: '/subscription-management',
    },
    {
        icon: Images.plansManagement,
        title: 'Plans Management',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
        to: '/plans-products',
    },
    {
        icon: Images.mtr,
        title: 'MTR Management',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
        to: 'mtr',
    },
    {
        icon: Images.reportGen,
        title: 'Report Generation',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
        to: 'report',
    },
    {
        icon: Images.charting,
        title: 'Charting And Metrics',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
        to: '/charts-metrics',
    },
    {
        next: true,
        title: 'Explore all features',
    },
];

export class FeaturesPurchaseFly extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }
    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    calculateList = (j, index) => {
        let items = [];
        let t = [];
        for (let i = 0; i < 6; i++) {
            if (i !== index) {
                t.push(i);
                if (t.length === j) {
                    items.push(t);
                    t = [];
                }
            }
        }
        items.push(t);
        return items;
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        const { screenWidth } = this.state;
        const { title, index } = this.props;

        let items = [];

        if (screenWidth < 1550) {
            items = this.calculateList(2, index);
        } else {
            items = this.calculateList(3, index);
        }

        return (
            <Row type="flex">
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Space height={50} />
                    <ComponentText
                        text={title}
                        align="center"
                        lineHeight={'45px'}
                        font={fonts.SFProDisplaySemiBold}
                        size={40}
                        color={Colors.dark}
                    />
                    <Space height={60} />

                    {items.map((row, i) => (
                        <div key={i}>
                            <Row gutter={[20, 20]}>
                                {row.map((e, j) => {
                                    let item = featuresPurchaseFly[e];
                                    return item.next === true ? (
                                        <ComponentPrepareNext
                                            key={i + j}
                                            title={item.title}
                                            span={items[0].length === 2 ? 12 : 8}
                                            to={item.to}
                                        />
                                    ) : (
                                        <FeatureButton
                                            key={i + j}
                                            image={item.icon}
                                            title={item.title}
                                            description={item.description}
                                            to={item.to}
                                            span={items[0].length === 2 ? 12 : 8}
                                        />
                                    );
                                })}
                            </Row>
                            <Space height={20} />
                        </div>
                    ))}
                    {/*<Row gutter={20}>*/}
                    {/*    {featuresPurchaseFly.slice(0,3).map((e, i)=>*/}
                    {/*        <FeatureButton key={i} image={e.icon} title={e.title} description={e.description} to={e.to}/>*/}
                    {/*    )}*/}
                    {/*</Row>*/}
                    <Space height={80} />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        const { title, index } = this.props;

        let items = [];
        for (let i = 0; i < 6; i++) {
            if (i !== index) {
                items.push(i);
            }
        }

        //items = this.calculateList(1, index);

        return (
            <Row style={{ marginBottom: 60 }}>
                <ComponentText
                    text={title.replace('<br/>', ' ')}
                    align="center"
                    font={fonts.SFProDisplaySemiBold}
                    size={32}
                    color={Colors.dark}
                />
                <Space height={40} />

                <div style={{ overflowX: 'scroll', display: 'flex', height: 230 }}>
                    {items.map((i, k) => {
                        let item = featuresPurchaseFly[i];
                        return item.next === true ? (
                            <Row key={i} className={'feature-item-mobile-fake'} />
                        ) : (
                            <FeatureButtonMobile
                                key={i}
                                image={item.icon}
                                title={item.title}
                                description={item.description}
                                to={item.to}
                            />
                        );
                    })}
                </div>
                <ComponentPrepareNextMobile
                    key={5}
                    title={featuresPurchaseFly[5].title}
                    to={featuresPurchaseFly[5].to}
                />
            </Row>
        );
    }
}
function ComponentPrepareNext({ title, to, span }) {
    return (
        <a href={to}>
            {' '}
            <Col span={span ? span : 8}>
                <Row
                    className={'feature-item'}
                    type={'flex'}
                    align={'middle'}
                    justify={'center'}
                    style={{ height: '100%' }}
                >
                    {/*<Row type={"flex"} align={"middle"} style={{top: '50%'}}>*/}
                    <ComponentText
                        text={title}
                        align="center"
                        font={fonts.SFProDisplaySemiBold}
                        size={18}
                        color={Colors.deep_sky_blue}
                    />
                    <Space width={12} />
                    <ComponentIcon type={'arrow-right'} />
                    {/*</Row>*/}
                </Row>
            </Col>
        </a>
    );
}
function ComponentPrepareNextMobile({ title, to }) {
    return (
        <a href={to}>
            <Row type={'flex'} justify="center" align={'middle'} style={{ margin: '16px' }}>
                {/*<Row type={"flex"} align={"middle"} style={{top: '50%'}}>*/}
                <ComponentText
                    text={title}
                    align="center"
                    font={fonts.SFProDisplaySemiBold}
                    size={18}
                    color={Colors.deep_sky_blue}
                />
                <Space width={12} />
                <ComponentIcon type={'arrow-right'} />
                {/*</Row>*/}
            </Row>
        </a>
    );
}
