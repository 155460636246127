import React from 'react';
import { Col, Divider, Form, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { InputComponent } from '../Shared/ComponentForm/InputComponent';
import { prepareInput } from '../../utils/FormUtils';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import Space from '../Shared/ComponentUI/Spcae';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Contact our sales team';
const description1 =
    'Purchasefly works closely with you to develop custom pricing models<br/> which factor in your industry, risk profile, and transactional volume.';
const description2 =
    "Together we'll create a powerful enterprise-class mobile subscription<br/> that boosts your bottom line.";

class ContactUsBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: 1400,
            name: prepareInput('name', 'Full Name', false, 'Enter your full name', '', ''),
            email: prepareInput('email', 'Email Address', false, 'Enter your email address', '', ''),
            mobile_number: prepareInput('mobile_number', 'Mobile Number', false, 'Enter your email address', '', ''),
            company_name: prepareInput('company_name', 'Company Name', false, 'Enter your company name', '', ''),
            message: prepareInput('message', 'Your Message', false, 'Write your message here', '', ''),
        };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type="flex" style={{ marginTop: 160, marginBottom: 50 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Row type="flex" justify={'center'}>
                        <Row>
                            <ComponentText
                                text={title}
                                font={fonts.SFProDisplaySemiBold}
                                size={60}
                                color={Colors.black}
                            />
                            <Space height={40} />
                        </Row>
                        <Row>
                            <ComponentText
                                text={description1}
                                font={fonts.SFProDisplayRegular}
                                size={22}
                                color={Colors.slate_grey}
                            />
                            <Space height={40} />
                        </Row>
                        <Row>
                            <ComponentText
                                text={description2}
                                font={fonts.SFProDisplayRegular}
                                size={22}
                                color={Colors.slate}
                            />
                            <Space height={68} />
                        </Row>
                        <Row>
                            <div className="form-container">
                                <Form onSubmit={this.handleSubmit} layout="vertical">
                                    <InputComponent
                                        form={this.props.form}
                                        layout={formItemLayout}
                                        values={this.state.name}
                                    />
                                    <InputComponent
                                        form={this.props.form}
                                        layout={formItemLayout}
                                        values={this.state.email}
                                    />
                                    <InputComponent
                                        form={this.props.form}
                                        layout={formItemLayout}
                                        values={this.state.mobile_number}
                                    />
                                    <InputComponent
                                        form={this.props.form}
                                        layout={formItemLayout}
                                        values={this.state.company_name}
                                    />
                                    <InputComponent
                                        textArea={true}
                                        form={this.props.form}
                                        layout={formItemLayout}
                                        values={this.state.message}
                                    />
                                    <Form.Item className="no-bottom-margin">
                                        <ComponentButton
                                            htmlType="submit"
                                            text={'Send Message'}
                                            css={'deep-sky-blue-and-white'}
                                            height={60}
                                            width={430}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        </Row>
                    </Row>
                    <Space height={150} />
                    <Divider />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ marginTop: 60, marginBottom: 40 }}>
                <ComponentText
                    text={title}
                    font={fonts.SFProDisplaySemiBold}
                    size={32}
                    color={Colors.black}
                    align={'center'}
                />
                <Space height={20} />
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={description1.replace('<br/>', ' ')}
                        font={fonts.SFProDisplayRegular}
                        size={18}
                        color={Colors.slate_grey}
                    />
                </div>
                <Space height={20} />
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={description2.replace('<br/>', ' ')}
                        font={fonts.SFProDisplayRegular}
                        size={18}
                        color={Colors.slate}
                    />
                </div>
                <Space height={40} />
                <div className="form-container">
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <InputComponent form={this.props.form} layout={formItemLayout} values={this.state.name} />
                        <InputComponent form={this.props.form} layout={formItemLayout} values={this.state.email} />
                        <InputComponent
                            form={this.props.form}
                            layout={formItemLayout}
                            values={this.state.mobile_number}
                        />
                        <InputComponent
                            form={this.props.form}
                            layout={formItemLayout}
                            values={this.state.company_name}
                        />
                        <InputComponent
                            textArea={true}
                            form={this.props.form}
                            layout={formItemLayout}
                            values={this.state.message}
                        />
                        <Form.Item className="no-bottom-margin">
                            <ComponentButton
                                htmlType="submit"
                                text={'Send Message'}
                                css={'deep-sky-blue-and-white-mobile'}
                                height={40}
                                width={200}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <Space height={80} />
                <Divider />
            </Row>
        );
    }
}

const Banner = Form.create({ name: 'contact-us' })(ContactUsBanner);
export default Banner;

const formItemLayout = {
    labelCol: {
        xs: { span: 2 },
        sm: { span: 2 },
    },
    wrapperCol: {
        xs: { span: 2 },
        sm: { span: 2 },
    },
};
