import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import Space from '../Shared/ComponentUI/Spcae';
import { ComponentIcon } from '../Shared/ComponentUI/ComponentIcon';
import { CodeSnippet } from '../Shared/CodeSnippet/CodeSnippet';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

export class Features extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        const { contents, title } = this.props;
        return (
            <Row type={'flex'} align={'middle'} justify={'space-between'}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    {title && (
                        <div>
                            {' '}
                            <ComponentText
                                text={title}
                                font={fonts.SFProDisplayBold}
                                size={50}
                                color={Colors.black_four}
                                align={'center'}
                            />
                            <Space height={120} />
                        </div>
                    )}
                    {contents.map((e, i) =>
                        e.left === true ? <PrepareItem key={i} item={e} /> : <PrepareItemRight key={i} item={e} />
                    )}
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        const { contents, title } = this.props;
        return (
            <Row style={{ marginTop: 50 }}>
                {title && (
                    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <ComponentText
                            text={title.replace('<br/>', ' ')}
                            font={fonts.SFProDisplayBold}
                            size={32}
                            color={Colors.black_four}
                            align={'center'}
                        />
                        <Space height={60} />
                    </div>
                )}
                {contents.map((e, i) => (
                    <PrepareItemMobile key={i} item={e} />
                ))}
            </Row>
        );
    }
}

function PrepareItem({ item }) {
    return (
        <Row type={'flex'} align={'middle'} justify={'center'} style={{ marginBottom: 150 }}>
            <Col span={item.spans[0]}>
                <img src={item.image} width={'100%'} alt={''} className={item.shadow ? 'feature-item-shadow' : ''} />
            </Col>

            <Col span={item.spans[1]} style={{ paddingLeft: item.space }}>
                <ComponentText
                    text={item.title}
                    font={fonts.SFProDisplayBold}
                    size={50}
                    color={Colors.black_four}
                    align={'left'}
                />
                <Space height={40} />
                <ComponentText
                    text={item.description}
                    font={fonts.SFProDisplayRegular}
                    size={20}
                    color={Colors.battleship_grey}
                    align={'left'}
                />
                <Space height={36} />
                <a href={item.link}>
                    <Row type={'flex'} align={'middle'}>
                        <ComponentText
                            text={'Learn More'}
                            font={fonts.SFProDisplaySemiBold}
                            size={15}
                            color={Colors.deep_sky_blue}
                            align={'left'}
                        />
                        <Space width={12} />
                        <div style={{ paddingTop: 4 }}>
                            <ComponentIcon type={'arrow-right'} />
                        </div>
                    </Row>
                </a>
            </Col>
        </Row>
    );
}

function PrepareItemRight({ item }) {
    return (
        <Row type={'flex'} align={'middle'} justify={'center'} style={{ marginBottom: 150 }}>
            <Col span={item.spans[0]} style={{ paddingRight: item.space }}>
                <ComponentText
                    text={item.title}
                    font={fonts.SFProDisplayBold}
                    size={50}
                    color={Colors.black_four}
                    align={'left'}
                />
                <Space height={40} />
                <ComponentText
                    text={item.description}
                    font={fonts.SFProDisplayRegular}
                    size={20}
                    color={Colors.battleship_grey}
                    align={'left'}
                />
                <Space height={36} />
                <a href={item.link}>
                    <Row type={'flex'} align={'middle'}>
                        <ComponentText
                            text={'Learn More'}
                            font={fonts.SFProDisplaySemiBold}
                            size={15}
                            color={Colors.deep_sky_blue}
                            align={'left'}
                        />
                        <Space width={12} />
                        <div style={{ paddingTop: 4 }}>
                            <ComponentIcon type={'arrow-right'} />
                        </div>
                    </Row>
                </a>
            </Col>
            <Col span={item.spans[1]}>
                {item.codeSnippet === true ? (
                    <CodeSnippet width={'100%'} />
                ) : (
                    <img
                        src={item.image}
                        width={'100%'}
                        alt={''}
                        className={item.shadow ? 'feature-item-shadow' : ''}
                    />
                )}
            </Col>
        </Row>
    );
}

function PrepareItemMobile({ item }) {
    return (
        <Row style={{ marginBottom: 60 }}>
            {item.codeSnippet === true ? (
                <CodeSnippet width={'90%'} />
            ) : (
                <img src={item.image} width={'90%'} alt={''} className={item.shadow ? 'feature-item-shadow' : ''} />
            )}
            <Space height={36} />
            <div style={{ paddingLeft: 50, paddingRight: 50 }}>
                <ComponentText
                    text={item.title}
                    font={fonts.SFProDisplayBold}
                    size={32}
                    color={Colors.black_four}
                    align={'center'}
                />
            </div>
            <Space height={22} />
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <ComponentText
                    text={item.description}
                    font={fonts.SFProDisplayRegular}
                    size={16}
                    color={Colors.battleship_grey}
                    align={'center'}
                />
            </div>
            <Space height={22} />
            <a href={item.link}>
                <Row type={'flex'} align={'middle'} justify={'center'}>
                    <ComponentText
                        text={'Learn More'}
                        font={fonts.SFProDisplaySemiBold}
                        size={18}
                        color={Colors.deep_sky_blue}
                        align={'left'}
                    />
                    <Space width={12} />
                    <div style={{ paddingTop: 4 }}>
                        <ComponentIcon type={'arrow-right'} />
                    </div>
                </Row>
            </a>
        </Row>
    );
}
