export const prepareInput = (name, label, required, placeholder, extra, message) => {
    return {
        name: name,
        required: true,
        label: label,
        placeholder: placeholder,
        rules: [{ required: required, message: message }],
        extra: extra,
    };
};
