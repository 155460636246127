import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Space from '../Shared/ComponentUI/Spcae';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { FeatureButton } from '../Shared/FeatureButton';
import Images from '../../images/images';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Let’s build your<br/>subscription business';
const description = 'Here is all the documentation to get started!';

const features = [
    {
        icon: Images.ic_subscription,
        title: 'Getting Started',
        description: 'A detailed overview of how Purchasefly works and what you need to get started',
        to: '/subscription-management',
    },
    {
        icon: Images.ic_add_app,
        title: 'Adding your App',
        description: 'Setting up and syncing your app is a vital step in the process. Learn how to do that. ',
        to: '/plans-products',
    },
    {
        icon: Images.ic_resource,
        title: 'Resources',
        description: 'Browse all the necessary tutorials and resources to grow your subscription business. ',
        to: '/resource',
    },
];

export class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        return (
            <Row type="flex" align={'top'}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={50}
                        color={Colors.white}
                        align={'center'}
                    />
                    <Space height={30} />
                    <ComponentText
                        text={description}
                        font={fonts.SFProDisplayRegular}
                        size={22}
                        color={Colors.white}
                        align={'center'}
                    />
                    <Space height={80} />
                    <Row type={'flex'} justify={'space-between'} align={'middle'} gutter={20}>
                        {features.map((e, i) => (
                            <FeatureButton
                                key={i}
                                image={e.icon}
                                title={e.title}
                                description={e.description}
                                to={e.to}
                            />
                        ))}
                    </Row>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
}
