import Images from '../../images/images';
import React from 'react';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import Space from '../Shared/ComponentUI/Spcae';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import { Col, Radio, Row, Spin } from 'antd';
import { ComponentDivider } from '../Shared/ComponentDivider';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';
import BlocBuilder from 'bloc-builder-react';
import PricingBloc from './PricingBloc';
import { planTypes, productTypes } from './PricingPlans';

const title = 'Pricing Plans';
const description = 'From getting started to millions of users, we have a plan for you';
export class Plans extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
        PricingBloc.instance.setProductAndPlan(this.getSelectedPlan(), this.getSelectedProduct());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if(prevProps.location !== this.props.location){
        //     console.log(this.props.location);
        //     PricingBloc.setProductAndPlan(this.getSelectedPlan(), this.getSelectedProduct());
        // }
    }

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }
    onChange = (e) => {
        this.addQuery({ key: 'plan', value: e.target.value });
    };

    onClick = (x) => {
        this.addQuery(x);
    };

    addQuery = (query) => {
        const finalQuery = new URLSearchParams(this.props.location.search);
        finalQuery.set(query.key, query.value);

        PricingBloc.instance.setProductAndPlan(
            finalQuery.get('plan') ? finalQuery.get('plan') : planTypes.Monthly,
            finalQuery.get('product') ? finalQuery.get('product') : productTypes.Startup
        );
        this.props.history.push({
            pathname: '',
            search: '?' + new URLSearchParams(finalQuery).toString(),
        });
    };

    getSelectedPlan = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('plan');
        return token ? token : planTypes.Monthly;
    };

    getSelectedProduct = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('product');
        return token ? token : productTypes.Startup;
    };

    setTitle = (title) => {
        return (
            <BlocBuilder
                subject={PricingBloc.instance.getProductSubject()}
                builder={(snapshot) => {
                    return (
                        <ComponentText
                            text={title}
                            align="center"
                            font={fonts.SFProDisplaySemiBold}
                            size={30}
                            color={Colors.black}
                        />
                    );
                    // switch (!snapshot.error) {
                    //     case true:
                    //         let res = snapshot.data?.data ? snapshot.data.data[0].name: title;
                    //         return res;
                    //     default :
                    //       return title;
                    //   }
                }}
            />
        );
    };

    getPrice = (price, product) => {
        if (product.subscription_plans.length === 1) {
            return 'Free';
        } else {
            const selectedPlan = this.getSelectedPlan();
            const plan = product.subscription_plans.find((e) => e.type === selectedPlan);
            return `$${plan.price}`;
        }
    };
    getFeatureText = (text, product) => {
        if (text.includes('__MTR__')) {
            const mtr = product.features.find((e) => e.name === 'MTR-Limit');
            return text.replace('__MTR__', `$${mtr.value / 1000}k`);
        } else if (text.includes('__Collaborator__')) {
            const collaborator = product.features.find((e) => e.name === 'Collaborator-Num');
            return text.replace('__Collaborator__', `${collaborator.value}`);
        } else return text;
    };

    PreparePlan = (p, x) => {
        return (
            <div>
                <Row
                    className={p.title === this.getSelectedProduct() ? 'pricing-plan-selected' : 'pricing-plan'}
                    onClick={() => {
                        this.onClick({ key: 'product', value: p.title });
                    }}
                >
                    <Space height={42} />
                    <img alt="" src={p.icon} width={138} />
                    <Space height={24} />
                    {/* {this.setTitle(p.title)} */}
                    <ComponentText
                        text={p.title}
                        align="center"
                        font={fonts.SFProDisplaySemiBold}
                        size={30}
                        color={Colors.black}
                    />
                    <Space height={20} />
                    <ComponentText
                        text={this.getPrice(p.price, x)}
                        align="center"
                        font={fonts.SFProDisplayMedium}
                        size={22}
                        color={Colors.black}
                    />

                    <Row style={{ height: 320 }}>
                        <Space height={40} />
                        {p.features.map((s, i) => (
                            <div key={i}>
                                <ComponentText
                                    text={this.getFeatureText(s, x)}
                                    align="center"
                                    font={fonts.SFProDisplayMedium}
                                    size={16}
                                    color={Colors.slate_grey}
                                />
                                <Space height={16} />
                            </div>
                        ))}
                    </Row>
                    <div style={{ marginLeft: 24, marginRight: 24 }}>
                        <ComponentButton text={p.buttonText} css={p.buttonType} />
                    </div>
                    <Space height={30} />
                </Row>
                {p.popular === true && (
                    <Row type={'flex'} align={'middle'} justify={'center'}>
                        <div className={'plan-tag'}>
                            <ComponentText
                                text={'Popular'}
                                align="center"
                                font={fonts.SFProDisplayMedium}
                                size={16}
                                color={Colors.algae_green}
                            />
                        </div>
                    </Row>
                )}
            </div>
        );
    };

    PreparePlanMobile = (p, x) => {
        return (
            <div key={p.title}>
                <Row
                    className={p.title === this.getSelectedProduct() ? 'pricing-plan-selected' : 'pricing-plan'}
                    style={{ paddingRight: 24, paddingLeft: 24 }}
                    onClick={() => {
                        this.onClick({ key: 'product', value: p.title });
                    }}
                >
                    <Space height={32} />
                    <Row type={'flex'} justify={'space-between'} align={'middle'}>
                        <Col>
                            <ComponentText
                                text={p.title}
                                align="left"
                                font={fonts.SFProDisplaySemiBold}
                                size={24}
                                color={Colors.black}
                            />
                            <Space height={8} />
                            <ComponentText
                                text={this.getPrice(p.price, x)}
                                align="left"
                                font={fonts.SFProDisplayMedium}
                                size={18}
                                color={Colors.black}
                            />
                        </Col>
                        <Col>
                            <img alt="" src={p.icon} width={138} />
                        </Col>
                    </Row>

                    <Row>
                        <Space height={20} />
                        {p.features.map((s, i) => (
                            <div key={i}>
                                <ComponentText
                                    text={this.getFeatureText(s, x)}
                                    align="left"
                                    font={fonts.SFProDisplayMedium}
                                    size={16}
                                    color={Colors.slate_grey}
                                />
                                <Space height={16} />
                            </div>
                        ))}
                    </Row>
                    <ComponentButton text={p.buttonText} css={p.buttonType} />
                    <Space height={30} />
                </Row>

                {/* {p.popular === false && <Row type={"flex"} align={"middle"} justify={"center"}>
                <div className={"plan-tag"}>
                    <ComponentText text={"Popular"} align="center" font={fonts.SFProDisplayMedium}
                                   size={16}
                                   color={Colors.algae_green}/>
                </div>
            </Row>} */}
            </div>
        );
    };

    prepareView() {
        return (
            <Row type="flex" justify="center" style={{ marginTop: 120, marginBottom: 60 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={60}
                        color={Colors.black}
                        align={'center'}
                    />
                    <Space height={40} />
                    <ComponentText
                        text={description}
                        font={fonts.SFProDisplayRegular}
                        size={22}
                        color={Colors.slate_grey}
                        align={'center'}
                    />
                    <Space height={45} />
                    <Row type="flex" justify="center" align="middle">
                        <BlocBuilder
                            subject={PricingBloc.instance.getSavings()}
                            builder={(snapshot) => {
                                return snapshot.data ? (
                                    <Col>
                                        <ComponentText
                                            text={`Save ${snapshot.data}%`}
                                            font={fonts.SFProDisplayRegular}
                                            size={14}
                                            color={Colors.deep_sky_blue}
                                            align={'center'}
                                        />
                                        <img src={Images.saveArrow} alt={''} height={24} />
                                        <Space height={28} />
                                    </Col>
                                ) : (
                                    <div style={{ width: 60, height: 73 }} />
                                );
                            }}
                        />
                        {/* <Col>
                        <ComponentText text={"Save 15%"} font={fonts.SFProDisplayRegular} size={14} color={Colors.deep_sky_blue} align={"center"}/>
                        <img src={Images.saveArrow} alt={""} height={24}/>
                        <Space height={28}/>
                    </Col> */}
                        <Space width={10} />
                        <Row type="flex" justify="center" align="middle" className={'radio-plan-selection'}>
                            <Radio.Group
                                name="radiogroup"
                                defaultValue={planTypes.Monthly}
                                onChange={this.onChange}
                                value={this.getSelectedPlan()}
                            >
                                <Row type="flex" justify="center" align="middle">
                                    <Radio value={planTypes.Annual} style={{ paddingBottom: 4, paddingLeft: 10 }}>
                                        <a
                                            className="medium-slate-grey"
                                            onClick={() => {
                                                this.onClick({ key: 'plan', value: planTypes.Annual });
                                            }}
                                        >
                                            Annually{' '}
                                        </a>
                                    </Radio>
                                    <ComponentDivider width={1} height="55px" />
                                    <Radio value={planTypes.Monthly} style={{ paddingBottom: 4, paddingLeft: 8 }}>
                                        <a
                                            className="medium-slate-grey"
                                            onClick={() => {
                                                this.onClick({ key: 'plan', value: planTypes.Monthly });
                                            }}
                                        >
                                            Monthly
                                        </a>
                                    </Radio>
                                </Row>
                            </Radio.Group>
                        </Row>
                        <Space width={84} />
                    </Row>
                    <Space height={45} />
                    <BlocBuilder
                        subject={PricingBloc.instance.getProductSubject()}
                        builder={(snapshot) => {
                            return snapshot?.data ? (
                                <Row type={'flex'} gutter={40}>
                                    {[0, 1, 2].map((i) => (
                                        <Col key={i} span={8}>
                                            {this.PreparePlan(
                                                plans[i],
                                                snapshot.data.find((e) => {
                                                    return e.name === plans[i].title;
                                                })
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Spin />
                            );
                        }}
                    />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ marginTop: 60, marginBottom: 60 }}>
                <ComponentText
                    text={title}
                    font={fonts.SFProDisplayBold}
                    size={32}
                    color={Colors.black}
                    align={'center'}
                />
                <Space height={40} />
                <div style={{ marginLeft: 60, marginRight: 60 }}>
                    <ComponentText
                        text={description}
                        font={fonts.SFProDisplayRegular}
                        size={16}
                        color={Colors.slate_grey}
                        align={'center'}
                    />
                </div>
                <Space height={40} />
                <Row type="flex" justify="center" align="middle">
                    <BlocBuilder
                        subject={PricingBloc.instance.getSavings()}
                        builder={(snapshot) => {
                            return snapshot.data ? (
                                <Col>
                                    <ComponentText
                                        text={`Save ${snapshot.data}%`}
                                        font={fonts.SFProDisplayRegular}
                                        size={14}
                                        color={Colors.deep_sky_blue}
                                        align={'center'}
                                    />
                                    <img src={Images.saveArrow} alt={''} height={24} />
                                    <Space height={28} />
                                </Col>
                            ) : (
                                <div style={{ width: 0, height: 73 }} />
                            );
                        }}
                    />
                    <Row type="flex" justify="center" align="middle" className={'radio-plan-selection'}>
                        <Radio.Group
                            name="radiogroup"
                            defaultValue={planTypes.Monthly}
                            onChange={this.onChange}
                            value={this.getSelectedPlan()}
                        >
                            <Row type="flex" justify="center" align="middle">
                                <Radio value={planTypes.Annual} style={{ paddingBottom: 4, paddingLeft: 10 }}>
                                    <a
                                        onClick={() => {
                                            this.onClick({ key: 'plan', value: planTypes.Annual });
                                        }}
                                        className="medium-slate-grey"
                                    >
                                        Annually{' '}
                                    </a>
                                </Radio>
                                <ComponentDivider width={1} height="55px" />
                                <Radio value={planTypes.Monthly} style={{ paddingBottom: 4, paddingLeft: 8 }}>
                                    <a
                                        onClick={() => {
                                            this.onClick({ key: 'plan', value: planTypes.Monthly });
                                        }}
                                        className="medium-slate-grey"
                                    >
                                        Monthly
                                    </a>
                                </Radio>
                            </Row>
                        </Radio.Group>
                    </Row>
                </Row>
                <Space height={40} />
                <Row style={{ paddingLeft: 14, paddingRight: 14 }}>
                    <BlocBuilder
                        subject={PricingBloc.instance.getProductSubject()}
                        builder={(snapshot) => {
                            return snapshot.data?.data ? (
                                [0, 1, 2].map((i) =>
                                    this.PreparePlanMobile(
                                        plans[i],
                                        snapshot.data.data.find((e) => e.name === plans[i].title)
                                    )
                                )
                            ) : (
                                <Spin />
                            );
                        }}
                    />
                </Row>
            </Row>
        );
    }
}

let basicFeatures = [
    'Up to __MTR__ MTR',
    'All Platform SDKs',
    'CustomerProfile Status Tracking',
    'Receipt Validation',
    'Necessary Metrics View',
];
let businessFeatures = [
    '__MTR__ MTR Included',
    'Attribution Source Tracking',
    'All Integrations',
    'Advanced Charting and Metrics',
    'Up to __Collaborator__ Collaborators',
    'Premium Support',
    'Raw Data Exports',
];
let startupFeatures = [
    '__MTR__ MTR Included',
    'Apple Search Ads Dashboard',
    'Server-to-Server Webhooks',
    'Up to __Collaborator__ Collaborators',
    'Advanced Charting and Metrics',
];
const plans = [
    {
        icon: Images.planBasic,
        style: 'basic-plan',
        title: 'Build',
        price: 'Free',
        //description: "No credit card required,<br/> no trial period.",
        buttonText: 'Get Started Now',
        buttonType: 'button-create-account',
        features: basicFeatures,
        popular: false,
    },
    {
        icon: Images.planStartUp,
        style: 'startup-plan',
        title: 'Analyze',
        price: '$79',
        //description: "Per month billed annually,<br/> or $89 billed monthly",
        buttonText: 'Start 7 Day Free Trial',
        buttonType: 'button-try-free',
        features: startupFeatures,
        popular: true,
    },
    {
        icon: Images.planBusiness,
        style: 'business-plan',
        title: 'Business',
        price: '$299',
        //description: "Per month billed annually,<br/> or $329 billed monthly",
        buttonText: 'Start 7 Day Free Trial',
        buttonType: 'button-try-free',
        features: businessFeatures,
        popular: false,
    },
];
