const Images = {
    logo: require('./logo@3x.png'),
    chatflix: 'https://ik.imagekit.io/purchasefly/purchasefly/app-icons-chatflix_3x_7cMUszZ9oL.png',
    prankdial: 'https://ik.imagekit.io/purchasefly/purchasefly/app-icons-prankdial_3x_nKQ_s6e1s.png',
    prankpad: 'https://ik.imagekit.io/purchasefly/purchasefly/app-icons-prankpad_3x_6I4fPZAUM.png',
    textr: 'https://ik.imagekit.io/purchasefly/purchasefly/app-icons-textr_3x_vl6Leta1f5.png',
    phoneline: 'https://ik.imagekit.io/purchasefly/purchasefly/app-icons-phoneline_3x_H5xbMDybB.png',

    howItWorksTracking: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/how-to-04_3x_ogaN_SKMeu.png',
    howItWorksSubscribe: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/how-to-02_3x_4j8EnEoDft.png',
    howItWorksApps: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/how-to-01_3x_YJYQEnl7u.png',
    howItWorksRevenue: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/how-to-03_3x_nOjFGeS6H.png',

    getStarted: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/get-started_3x_xhz5res2m.png',

    featureBuiltForDev: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/code-embed_3x__ZhQXDIFn.png',
    featureDeploy: 'https://ik.imagekit.io/purchasefly/purchasefly/feature/feature-deploy_3x_hTGrqHNE0.png',
    featureLessFragment:
        'https://ik.imagekit.io/purchasefly/purchasefly/feature/feature-less-fragment_3x_xgEmS35rM.png',
    featureSegment: 'https://ik.imagekit.io/purchasefly/purchasefly/feature/feature-segment_3x_ZZXUZJf07.png',

    platformConnectScreenShot:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/connect-screenshot_3x_t85U_OgPY.png',
    platformConnect: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/connect_3x_vqc1Dnz81F.png',
    platformConnectSelected: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/connect-selected_3x_HKf5gdcgN.png',

    platformControlScreenShot:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/connect-screenshot_3x_30GT977g6.png',
    platformControl: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/control_3x_oo8gkJpzn8.png',
    platformControlSelected: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/control-selected_3x_yV4tO_QBZ.png',

    platformTrackScreenShot:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/track-screenshot_3x_eeCzH2Y8xI.png',
    platformTrack: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/track_3x_1jjDRP9BB.png',
    platformTrackSelected: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/track-selected_3x_68xNl5Uz6.png',

    clearScore: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/ClearScore-logo_IJZCn8ugR.png',
    facebook: 'https://ik.imagekit.io/purchasefly/purchasefly/footer/footer-facebook_3x_8G9Sb-Xuj.png',
    linkedin: 'https://ik.imagekit.io/purchasefly/purchasefly/footer/footer-linkedin_3x_iy8p1MQmw.png',
    twitter: 'https://ik.imagekit.io/purchasefly/purchasefly/footer/footer-twitter_3x_VVrVaCTW2_.png',
    facebookGray: 'https://ik.imagekit.io/purchasefly/purchasefly/footer/footer-gray-facebook_3x_v5HRNGJZO1.png',
    linkedinGray: 'https://ik.imagekit.io/purchasefly/purchasefly/footer/footer-gray-linkedin_3x_oGZwIihZjX.png',
    twitterGray: 'https://ik.imagekit.io/purchasefly/purchasefly/footer/footer-gray-twitter_3x_a82rzcf7P.png',

    quote: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/quote_3x_IfS56umYU.png',
    quoteBlue: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/quote_3x_q_hTuXaIg.png',
    arrow: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/arrow_down_1_3x_RF4E4LHpB.png',
    hero_animation: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/hero_image_animation_3x_dSe4s2zQt.png',

    charting: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charting_3x_SQyVBRrdL.png',
    mtr: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/mtr_manage_3x_I2cxi19niC.png',
    plansManagement: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plans_management_3x_mYeqIvZP1.png',
    subscriptionManagement: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/sub_management_3x_nVUYBejQB.png',
    reportGen: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/report_gen_3x_QQcF0tMty.png',
    customerProfile: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer-profile_3x_8uASZwozX.png',

    homeVideoOptimized:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/home/Optimized-Purchasefly_header_animation_v2_9_Ab0J8r0.mp4',

    //subscriptionManagement
    yourApp: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/your_app_3x_2Pl0XMIYA.png',
    trialStarted: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/trial_started_3x_SJ7Gz13VZ.png',

    subscriptionStarted:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/subscription_started_3x_gLrHngO4u.png',
    subscriptionRenewed:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/subscription_renewed_3x_jmILjtHvM.png',
    subscriptionExpired: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/sub_expired_3x_aoO3STij2.png',
    purchaseflyLogo: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/features_3x_nkhFbxTaN.png',

    makesDifferent1: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/makes_different_1_3x_Stk4LfEcF-.png',
    makesDifferent2: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/makes_different_2_3x_lXP4_LP0zU.png',
    makesDifferent3: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/makes_different_3_3x_TFvVScQWa.png',

    analyseSubscriber: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/02_1_asset_1_3x_zfxkDxEDd.png',
    configureSync: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/02_1_asset_2_3x_sQtB5PDaj.png',
    realTimeSubscription: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/02_1_asset_3_3x_7HOsPBGXg.png',
    developEasily: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/code_embed_3x_o-PcPYna0.png',
    antonina: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/antonina_3x_ZpKDJFSwl.png',

    bannerSubscription: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/02_1_screenshot_3x_-42zDM6jq.png',
    arrowRight: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/arrow_copy_3x_c2k7t4d9M.png',
    arrowDown: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/feature/arrow_copy_4_3x_iXfrmxFPGk.png',
    arrowTopDown: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/arrow_copy_5_3x_2yqUOpwDZ.png',

    //plans & product
    bannerPlans: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/products/02_3_screenshot_3x_c-nWn9VQA.png',
    validateSubscription: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/products/02_3_asset_2_3x_0qFzEy3Sq.png',

    makesPlanDifferent1: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/products/02_3_icon_1_3x_D40wckr7r.png',
    makesPlanDifferent2: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/products/02_3_icon_2_3x_k1XqBSFiJ.png',
    makesPlanDifferent3: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/products/02_3_icon_3_3x_y4bThlkOr.png',

    configureProduct: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/products/02_3_blk_image_3x_LzC7qK4q4.png',
    configureProductMobile: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/connect_screenshot_3x_SGwU3P3-i.png',

    //customer profile
    bannerCustomer: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/02_4_screenshot_3x_yEk8G0lRC.png',
    monitorSubscription: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/02_4_asset_1_3x_2I1dFv_Dx.png',
    offerTrial: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/02_4_asset_2_3x_TUkjRB9uc.png',

    dynamicView1: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/02_4_icon_1_3x_Ji0-VPIQo.png',
    dynamicView2: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/02_4_icon_2_3x_ccEDl_000.png',
    dynamicView3: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/02_4_icon_3_3x_nD6N4v8q7.png',

    customerTrialStarted: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/trial_started_3x_aJgThSjNz.png',
    customerMonthly:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/subscription_started_3x_1JOn80L6OK.png',
    customerYearly:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/sbuscripition_renewed_3x_ACghLogcKQ.png',
    arrowDot: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/customer/arrow_copy_4_3x_VZVYQhint.png',
    arrowDotMobile: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/arrow_copy_3x_9uuWYLNOH.png',

    //chart metrics
    bannerChart: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_screenshot_3x_O0Zsswxi7.png',
    sourceOfTruth1: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_icon_1_3x_9iPmt3FPT.png',
    sourceOfTruth2: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_icon_2_3x_NaOCfwzQz.png',
    sourceOfTruth3: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_icon_3_3x_TNCDGTp-w.png',

    makeInformedDecisions: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_asset_1_3x_f6mddPzWS.png',
    increaseConversion: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_asset_2_3x_ZYbDTPDMF.png',
    flexibleCharts: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/02_2_asset_3_3x_N3VSyfLLwX.png',

    uncoverGrowth: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/charts/connect_screenshot_3x_keIQizBii.png',

    //pricing
    planBasic: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/plan_basic_3x_vQU6bF1r7o.png',
    planStartUp: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/plan_startup_3x_fDxNGQUjGe.png',
    planBusiness: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/plan_business_3x_gDsWXLnUH.png',
    radioSelected: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/radio_selected_3x_MI4jd5PnC.png',
    radioUnselected: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/radio_3x_D4VZER5vj-.png',
    arrowUp: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/ic_arrow_drop_down_18_px_3x_fT7BDar9vb.png',
    check: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/check_3x_gdLGQekhD.png',

    dot: 'static/media/dot@3x.5368e3b5.png',
    aboutUs: 'https://ik.imagekit.io/purchasefly/purchasefly/about-us/about-us_3x_TbqG5fi5y.png',
    emailus: 'https://ik.imagekit.io/purchasefly/purchasefly/email-us_3x_-oB5z3kJW.png',
    support: 'https://ik.imagekit.io/purchasefly/purchasefly/support_3x_93fH8qCj0.png',
    saveArrow: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/plan/arrow_3x_oxn97310M.png',

    //documentation
    documentation: 'https://ik.imagekit.io/purchasefly/purchasefly/feature/feature-built-for-dev_3x_c2tKeDcYS.png',
    ic_add_app: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/documentation/add_app_3x_HTtP7aoQN.png',
    ic_resource:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/documentation/ic_library_books_48_px_3x_9NF86BkdKb.png',
    ic_subscription: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/documentation/sub_management_3x_WuO-lOjZ7.png',
    img_logo_documentation:
        'https://ik.imagekit.io/purchasefly/purchasefly-v2/documentation/group_2_copy_3x_zr0aIneVK.png',

    arrow_right_white: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/arrow_right_white_3x_ikRio0TNuf.png',
    arrow_right_blue: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/arrow_right_blue_3x_UCBFmMV18p.png',
    arrow_up: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/arrow_up_3x_rWwC5CxXm.png',
    ic_top: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/ic_top_3x_671A6r9rPt.png',

    //about
    hasan: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/about/hasan_BXlPi4VdO.jpg',
    sanjid: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/about/sanjid_2m5ye6yLM.jpg',
    talha: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/about/talha_Lrp-Om_dh.jpg',
    about_linkedin: 'https://ik.imagekit.io/purchasefly/purchasefly-v2/linkedin_1wEQmurMk.png',
};

export default Images;
