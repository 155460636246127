import React from 'react';
import { Col, Divider, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import Space from '../Shared/ComponentUI/Spcae';
import { Colors } from '../../css/Colors';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Just connect your app.<br/>Control, Track and Grow.';
const title2 = 'The all-in-one subscription<br/>management platform';

const platformFeatures = [
    {
        buttonText: 'Connect',
        title: 'Easily connect your app with a few simple steps.',
        icon: Images.platformConnect,
        iconSelected: Images.platformConnectSelected,
        screenShot: Images.platformConnectScreenShot,
    },
    {
        buttonText: 'Control',
        title: 'Control and manage your subscriptions and packages',
        icon: Images.platformControl,
        iconSelected: Images.platformControlSelected,
        screenShot: Images.platformControlScreenShot,
    },
    {
        buttonText: 'Track',
        title: 'Get all the key metrics of your in-app subscription business',
        icon: Images.platformTrack,
        iconSelected: Images.platformTrackSelected,
        screenShot: Images.platformTrackScreenShot,
    },
];
const features = [
    {
        image: Images.howItWorksApps,
        title: 'Add your apps',
        description: 'Connect and configure your android or iOS app with purchasefly.',
    },
    {
        image: Images.howItWorksSubscribe,
        title: 'Users Subscribe',
        description: 'Customer buys a in-app subscriptions plans and pay for it using in-app methods.',
    },
    {
        image: Images.howItWorksRevenue,
        title: 'Get the Revenue',
        description: 'Check subscription status, while hosting and configuring products from our dashboard.',
    },
    {
        image: Images.howItWorksTracking,
        title: 'Keep Track of everything',
        description: 'Get all the key metrics of your in-app subscription business in one convenient nest.',
    },
];
export class ConnectApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400, selected: 0, indicatorWidth: 0 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
        this.interval = setInterval(() => {
            let scroll = window.scrollY;
            if (scroll > 1600 && scroll < 3000) {
                this.setState({ indicatorWidth: (this.state.indicatorWidth + 1) % 300 });
                let x = Math.floor(this.state.indicatorWidth / 100);
                this.onClick(x);
            }
        }, 60);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        clearInterval(this.interval);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    onClick = (i) => {
        if (this.state.selected !== i) this.setState({ selected: i, indicatorWidth: i * 100 });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <div>
                {' '}
                <Row type="flex">
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                        <Row type="flex" justify="center">
                            <ComponentText
                                text={title}
                                align="center"
                                font={fonts.SFProDisplayBold}
                                size={50}
                                lineHeight={'55px'}
                                color={Colors.black_four}
                            />
                        </Row>
                        <Space height={84} />
                        <Row type="flex" justify="start">
                            {features.map((e, index) => (
                                <ComponentHowItWorksItem
                                    key={index}
                                    image={e.image}
                                    title={e.title}
                                    description={e.description}
                                    index={index}
                                />
                            ))}
                        </Row>
                        <Space height={64} />
                        <Divider />
                        <Space height={64} />
                        <ComponentText
                            text={title2}
                            align="center"
                            font={fonts.SFProDisplayBold}
                            size={50}
                            lineHeight={'55px'}
                            color={Colors.black_four}
                        />
                        <Space height={64} />
                        <Row gutter={24}>
                            {platformFeatures.map((e, i) => (
                                <Col span={8} key={i}>
                                    <div className={'button-connect-app'} onClick={() => this.onClick(i)}>
                                        <div>
                                            <Row
                                                type={'flex'}
                                                justify={'center'}
                                                align={'middle'}
                                                className={
                                                    this.state.selected === i
                                                        ? 'platform-button-selected'
                                                        : 'platform-button'
                                                }
                                            >
                                                <img
                                                    src={this.state.selected === i ? e.iconSelected : e.icon}
                                                    width={36}
                                                    alt={''}
                                                />
                                                <Space width={12} />
                                                <ComponentText
                                                    text={e.buttonText}
                                                    font={fonts.SFProDisplaySemiBold}
                                                    color={this.state.selected === i ? Colors.white : Colors.black}
                                                    size={24}
                                                />
                                            </Row>
                                            {this.state.selected === i && (
                                                <div
                                                    className={'platform-button-selected-indicator'}
                                                    style={{ width: `${this.state.indicatorWidth % 100}%` }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <Space height={40} />
                        <Row style={{ backgroundColor: '#000000' }}>
                            <Space height={50} />
                            <img
                                src={platformFeatures[this.state.selected].iconSelected}
                                width={40}
                                height={40}
                                alt={''}
                            />
                            <Space height={18} />
                            <ComponentText
                                text={platformFeatures[this.state.selected].title}
                                align="center"
                                font={fonts.SFProDisplayBold}
                                size={24}
                                color={Colors.white}
                            />
                            <Space height={62} />
                            <img src={platformFeatures[this.state.selected].screenShot} width={'80%'} alt={''} />
                            <Space height={90} />
                        </Row>
                    </Col>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                </Row>{' '}
                <Space height={150} />
            </div>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ marginBottom: 90, marginLeft: 16, marginRight: 16 }}>
                <Row type="flex" justify="center" style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title.replace('<br/>', ' ')}
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={Colors.black_four}
                    />
                </Row>
                <Space height={40} />
                <Row style={{ padding: 20 }}>
                    {features.map((e, index) => (
                        <ComponentHowItWorksItemMobile
                            key={index}
                            image={e.image}
                            title={e.title}
                            description={e.description}
                            index={index}
                        />
                    ))}
                </Row>

                <Divider />
                <Space height={54} />
                <Row type="flex" justify="center" style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title2.replace('<br/>', ' ')}
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={Colors.black_four}
                    />
                </Row>
                <Space height={56} />
                <Row gutter={24}>
                    {platformFeatures.map((e, i) => (
                        <Col span={8} key={i}>
                            <div className={'button-connect-app'} onClick={() => this.onClick(i)}>
                                <div>
                                    <Row
                                        type={'flex'}
                                        justify={'center'}
                                        align={'middle'}
                                        className={
                                            this.state.selected === i
                                                ? 'platform-button-selected-mobile'
                                                : 'platform-button-mobile'
                                        }
                                    >
                                        <img
                                            src={this.state.selected === i ? e.iconSelected : e.icon}
                                            width={24}
                                            alt={''}
                                        />
                                        <Space width={8} />
                                        <ComponentText
                                            text={e.buttonText}
                                            font={fonts.SFProDisplaySemiBold}
                                            color={this.state.selected === i ? Colors.white : Colors.black}
                                            size={16}
                                        />
                                    </Row>
                                    {this.state.selected === i && (
                                        <div
                                            className={'platform-button-selected-indicator'}
                                            style={{ width: `${this.state.indicatorWidth % 100}%` }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Space height={30} />
                <Row style={{ backgroundColor: '#000000' }}>
                    <Space height={18} />
                    <img src={platformFeatures[this.state.selected].iconSelected} width={20} height={20} alt={''} />
                    <Space height={18} />
                    <ComponentText
                        text={platformFeatures[this.state.selected].title}
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={12}
                        color={Colors.white}
                    />
                    <Space height={24} />
                    <img src={platformFeatures[this.state.selected].screenShot} width={'90%'} alt={''} />
                    <Space height={24} />
                </Row>
            </Row>
        );
    }
}

function ComponentHowItWorksItem({ image, title, description, index }) {
    return (
        <Col span={6} style={{ textAlign: 'center' }}>
            <Row type="flex" justify="center" align="middle">
                <Col span={4}>{index > 0 && <Divider />}</Col>
                <Col span={16}>
                    <img alt="" src={image} width="60%" />
                </Col>
                <Col span={4}>{index < 3 && <Divider />}</Col>
            </Row>
            <Space height={30} />
            <Row type="flex" justify="center">
                <ComponentText text={title} font={fonts.SFProDisplaySemiBold} size={20} color={'var(--dark)'} />
            </Row>
            <Space height={14} />
            <Row type="flex" justify="center" style={{ paddingLeft: 40, paddingRight: 40 }}>
                <ComponentText
                    text={description}
                    font={fonts.SFProDisplayRegular}
                    size={16}
                    color={'var(--battleship-grey)'}
                    lineHeight={'22px'}
                />
            </Row>
        </Col>
    );
}

function ComponentHowItWorksItemMobile({ image, title, description, index }) {
    return (
        <Row>
            <img alt="" src={image} width="50%" />
            <Space height={30} />
            <ComponentText text={title} font={fonts.SFProDisplaySemiBold} size={20} color={'var(--dark)'} />
            <Space height={14} />
            <ComponentText
                text={description}
                font={fonts.SFProDisplayRegular}
                size={16}
                color={'var(--battleship-grey)'}
            />
            <Space height={40} />
        </Row>
    );
}
