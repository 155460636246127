import React from 'react';
import './SubscriptionManagement.css';
import { Banner } from './Banner';

import { TopBar } from '../Shared/Menus/TopBar';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { Footer } from '../Shared/Menus/Footer';
import { MakesDifferent } from './MakesDifferent';
import { RealTimeSubscriptionState } from './RealTimeSubscriptionState';
import Images from '../../images/images';
import { FeaturesPurchaseFly } from '../Shared/FeaturesPurchaseFly';
import { Features } from './Features';
import { links } from '../Shared/Routes';
import { TrustedCustomer} from "../Home/TrustedCustomer";

class SubscriptionManagement extends React.Component {
    render() {
        return (
            <div>
                <TopBar />
                <Banner />
                <RealTimeSubscriptionState />
                <Features contents={features} title={'Features crafted to power<br/>your subscription'} />
                <MakesDifferent />
                <FeaturesPurchaseFly title={'There’s more for you'} index={0} />
                <TrustedCustomer/>
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}
export default SubscriptionManagement;

const features = [
    {
        image: Images.analyseSubscriber,
        title: 'Analyze your subscribers',
        description:
            'Is a certain user non-paying, in trial or has active subscription? Forget about UserDefaults and Keychain. Apphud will keep user state up-to-date using Apple servers.',
        link: links.Analyze,
        left: true,
        spans: [11, 13],
        space: 124,
    },
    {
        image: Images.configureSync,
        title: 'Configure & sync trial periods',
        description:
            'Offering a free trial is a great way to give prospective subscribers a sample of your product  before they commit to a paid subscription plan.',
        link: links.Configure,
        left: false,
        spans: [10, 14],
        space: 80,
    },
    {
        image: Images.realTimeSubscription,
        title: 'Real time subscription state',
        description:
            'Is a certain user non-paying, in trial or has active subscription? Forget about UserDefaults and Keychain. Apphud will keep user state up-to-date using Apple servers.',
        link: links.Subscription,
        left: true,
        spans: [11, 13],
        space: 124,
    },
    {
        codeSnippet: true,
        image: Images.developEasily,
        title: 'Deploy easily with a few lines of code',
        description: 'Offering a free trial is a great way to give prospective subscribers a sample.',
        link: links.Webhooks,
        left: false,
        spans: [10, 14],
        space: 80,
    },
];
