import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Space from '../Shared/ComponentUI/Spcae';
import { fonts } from '../../fonts/fonts';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import Images from '../../images/images';
import { Colors } from '../../css/Colors';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';
import { links } from '../Shared/Routes';

const hints = 'Customer Profile';
const title = 'Know your Customer Profile';
const description =
    'Get a unified view of all subscription and customer data in one place and engage with more context.';

export class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type="flex" align={'top'} style={{ marginTop: 170, marginBottom: 150 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 8, 1)} style={{ paddingRight: 62 }}>
                    <ComponentText
                        text={hints}
                        font={fonts.SFProDisplayRegular}
                        size={22}
                        color={Colors.deep_sky_blue}
                        align={'left'}
                    />
                    <Space height={16} />
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={60}
                        color={Colors.black_four}
                        align={'left'}
                    />
                    <Space height={30} />
                    <ComponentText
                        text={description}
                        font={fonts.SFProDisplayRegular}
                        size={22}
                        color={Colors.slate_grey_two}
                        align={'left'}
                    />
                    <Space height={56} />
                    <Row type="flex" justify="start">
                        <a href={links.GetStarted}>
                            <ComponentButton
                                text={'Get Started'}
                                css={'deep-sky-blue-and-white'}
                                iconRight={'arrow-right-white'}
                                height={50}
                                width={156}
                            />
                        </a>
                        <Space width={16} />
                        <a href={links.LiveDemo}>
                            <ComponentButton
                                text={'Live Demo'}
                                css={'white-and-deep-sky-blue'}
                                iconRight={'arrow-right'}
                                height={50}
                                width={156}
                            />
                        </a>
                    </Row>
                </Col>
                <Col span={getIncreasingSpan(this.state.screenWidth, 12, 0)}>
                    <img src={Images.bannerCustomer} width={'100%'} className={'banner-image'} alt={''} />
                </Col>
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ marginTop: 60, marginBottom: 80 }}>
                <ComponentText
                    text={hints}
                    font={fonts.SFProDisplayRegular}
                    size={16}
                    color={Colors.deep_sky_blue}
                    align={'center'}
                />
                <Space height={10} />
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={Colors.black_four}
                        align={'center'}
                    />
                </div>
                <Space height={22} />
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <ComponentText
                        text={description}
                        font={fonts.SFProDisplayRegular}
                        size={16}
                        color={Colors.slate_grey_two}
                        align={'center'}
                    />
                </div>
                <Space height={40} />

                <img src={Images.bannerCustomer} width={'90%'} className={'banner-image-mobile'} alt={''} />
                <Space height={40} />
                <Row type="flex" justify="center">
                    <a href={links.GetStarted}>
                        <ComponentButton
                            text={'Get Started'}
                            css={'deep-sky-blue-and-white-mobile'}
                            iconRight={'arrow-right-white'}
                            height={40}
                        />
                    </a>
                    <Space width={16} />
                    <a href={links.LiveDemo}>
                        <ComponentButton
                            text={'Live Demo'}
                            css={'white-and-deep-sky-blue-mobile'}
                            iconRight={'arrow-right'}
                            height={40}
                        />
                    </a>
                </Row>
            </Row>
        );
    }
}
