import React from 'react';
import { Col, Row } from 'antd';
import Space from './ComponentUI/Spcae';
import { ComponentText } from './ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { ComponentIcon } from './ComponentUI/ComponentIcon';

export class FeatureButton extends React.Component {
    render() {
        let { image, title, description, to, span } = this.props;
        return (
            <Col span={span ? span : 8} style={{ height: '100%' }}>
                <a href={to}>
                    <div className={'feature-item'} style={{ height: '100%' }}>
                        <Row type={'flex'} align={'middle'}>
                            <img alt="" src={image} style={{ width: 36 }} />
                            <Space width={16} />
                            <Row type={'flex'} align={'middle'}>
                                <ComponentText
                                    text={title}
                                    align="center"
                                    font={fonts.SFProDisplaySemiBold}
                                    size={18}
                                    color={Colors.dark}
                                />
                                <Space width={12} />
                                <ComponentIcon type={'arrow-right'} />
                            </Row>
                        </Row>

                        <Space height={16} />
                        <ComponentText
                            text={description}
                            align="start"
                            lineHeight={'20px'}
                            font={fonts.SFProDisplayRegular}
                            size={14}
                            color={Colors.slate_grey_two}
                        />
                    </div>
                </a>
            </Col>
        );
    }
}
