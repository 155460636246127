import React from 'react';
import './PricingPlans.css';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { Footer } from '../Shared/Menus/Footer';
import { TopBar } from '../Shared/Menus/TopBar';
import { FrequentlyAskedQuestions } from './FrequentlyAskedQuestions';
import { ComparePlans } from './ComparePlans';
import { Plans } from './Plans';
import { EstimateCost } from './EstimateCost';
import PricingBloc from './PricingBloc';

export const planTypes = { Annual: 'SubscriptionPlanTypeYearly', Monthly: 'SubscriptionPlanTypeMonthly' };
export const productTypes = { Basic: 'Build', Startup: 'Analyze', Business: 'Business' };

class PricingPlans extends React.Component {
    componentDidMount() {
        PricingBloc.instance.getProducts();
        PricingBloc.instance.getOverages();
    }
    render() {
        return (
            <div>
                <TopBar />
                <Plans location={this.props.location} history={this.props.history} />
                <EstimateCost />
                <ComparePlans location={this.props.location} history={this.props.history} />
                <FrequentlyAskedQuestions />
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}
export default PricingPlans;
