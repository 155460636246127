import React from 'react';
import { Carousel, Col, Row } from 'antd';
import { Colors } from '../../css/Colors';
import { fonts } from '../../fonts/fonts';
import Images from '../../images/images';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';

import Space from '../Shared/ComponentUI/Spcae';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Trusted by Start-ups and<br/>Enterprises alike ';
const description =
    "The customer support level is the best I've ever seen, and they're constantly adding new features and improving the existing ones.";
const customers = [
    {
        name: 'Mark Lodge',
        designation: 'Director Business Planning, ClearScore',
        image: Images.antonina,
        logo: Images.clearScore,
        percent: '35%',
        description: 'Increase in annual recurring<br/>revenue on the same<br/>expenditure structure and plan.',
    },
    {
        name: 'Hema Glenn',
        designation: 'Director Business Planning, ClearScore',
        image: Images.antonina,
        logo: Images.clearScore,
        percent: '25%',
        description: 'Increase in annual recurring revenue on the same expenditure structure and plan.',
    },
    {
        name: 'John Doe',
        designation: 'Director Business Planning, ClearScore',
        image: Images.antonina,
        logo: Images.clearScore,
        percent: '15%',
        description: 'Increase in annual recurring revenue on the same expenditure structure and plan.',
    },
    {
        name: 'Jenna Malone',
        designation: 'Director Business Planning, ClearScore',
        image: Images.antonina,
        logo: Images.clearScore,
        percent: '31%',
        description: 'Increase in annual recurring revenue on the same expenditure structure and plan.',
    },
];
export class TrustedCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }
    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    title = (size) => {
        return (
            <ComponentText
                text={title}
                align="left"
                font={fonts.SFProDisplayBold}
                color={Colors.black_four}
                size={size}
            />
        );
    };
    description = (size) => {
        return (
            <p className={'quote-text'} style={{ fontSize: size }}>
                <span style={{ marginLeft: 40 }} />
                {description}
            </p>
        );
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row
                className={'trusted-customer'}
                type="flex"
                style={{ paddingTop: 56, paddingBottom: 56, marginBottom: 100 }}
                align={'middle'}
            >
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    {this.title(50)}
                    <Carousel>
                        {customers.map((e, i) => (
                            <Row key={i} type={'flex'} justify={'space-between'} style={{ marginBottom: 180 }}>
                                <Col span={12}>
                                    <Space height={90} />
                                    <div className={'quote'}>
                                        <img src={Images.quote} width={30} className={'quote-image'} alt={''} />
                                        {this.description(22)}
                                    </div>
                                    <Space height={64} />
                                    <Row type="flex" justify="start" align={'middle'}>
                                        <img src={customers[0].image} width={80} alt={''} />
                                        <Space width={20} />
                                        <div>
                                            <ComponentText
                                                text={customers[0].name}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                color={Colors.black}
                                                size={22}
                                            />
                                            <ComponentText
                                                text={customers[0].designation}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                color={Colors.slate_grey_two}
                                                size={16}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <div className={'trusted-customer'}>
                                        <div className={'trusted-customer-border'}>
                                            <Space height={110} />
                                            <Row type={'flex'} align={'middle'}>
                                                <ComponentText
                                                    text={customers[0].percent}
                                                    align="left"
                                                    font={fonts.SFProDisplayBold}
                                                    color={Colors.white}
                                                    size={70}
                                                />
                                            </Row>
                                            <Space height={36} />
                                            <ComponentText
                                                text={customers[0].description}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                color={Colors.white}
                                                size={28}
                                            />
                                        </div>
                                        <div className={'trusted-customer-container'}>
                                            <img src={customers[0].logo} width={230} alt={''} />
                                            <Space height={64} />
                                            <Row type={'flex'} align={'middle'}>
                                                <ComponentText
                                                    text={customers[0].percent}
                                                    align="left"
                                                    font={fonts.SFProDisplayBold}
                                                    color={Colors.black_four}
                                                    size={70}
                                                />
                                                <Space width={14} />
                                                <img src={Images.arrow} width={32} alt={''} />
                                            </Row>
                                            <Space height={36} />
                                            <ComponentText
                                                text={customers[0].description}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                color={Colors.slate_grey_two}
                                                size={28}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Carousel>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row className={'trusted-customer-mobile'}>
                <div style={{ marginLeft: 40, marginRight: 40 }}>
                    <ComponentText
                        text={title.replace('<br/>', ' ')}
                        align="center"
                        font={fonts.SFProDisplaySemiBold}
                        color={Colors.black_four}
                        size={32}
                    />
                </div>
                <Space height={52} />
                <Carousel>
                    {customers.map((e, i) => (
                        <Row key={i}>
                            <Row type="flex" justify={'center'}>
                                <img src={Images.quote} width={30} alt={''} />
                            </Row>
                            <Space height={12} />
                            <div style={{ marginLeft: 40, marginRight: 40 }}>
                                <ComponentText
                                    text={description.replace('<br/>', ' ')}
                                    align="center"
                                    font={fonts.SFProDisplayRegular}
                                    color={Colors.slate_grey_two}
                                    size={28}
                                />
                            </div>
                            <Space height={40} />
                            <Row type="flex" justify={'center'}>
                                <img src={customers[0].image} width={80} alt={''} />
                            </Row>
                            <Space width={20} />
                            <ComponentText
                                text={customers[0].name}
                                align="center"
                                font={fonts.SFProDisplayRegular}
                                color={Colors.black}
                                size={22}
                            />
                            <ComponentText
                                text={customers[0].designation}
                                align="center"
                                font={fonts.SFProDisplayRegular}
                                color={Colors.slate_grey_two}
                                size={16}
                            />
                            <Space height={80} />
                        </Row>
                    ))}
                </Carousel>
                <Space height={40} />
            </Row>
        );
    }
}
