import React from 'react';
import Images from '../../../images/images';

export class Logo extends React.Component {
    render() {
        return (
            <a href={'/home'}>
                <img src={Images.logo} height={36} alt={''} />
            </a>
        );
    }
}
