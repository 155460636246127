import * as rxjs from 'rxjs';
import Axios from 'axios';

export default class PricingBloc {
    static instance = PricingBloc.instance || new PricingBloc();

    constructor() {
        this.productSubject = new rxjs.BehaviorSubject([]);
        this.overageSubject = new rxjs.BehaviorSubject([]);
        this.selectedProductSubject = new rxjs.BehaviorSubject('');
        this.selectedPlanSubject = new rxjs.BehaviorSubject('');
        this.selectedMTR = new rxjs.BehaviorSubject(0);
        this.savings = new rxjs.BehaviorSubject(0);
        this.billing = new rxjs.BehaviorSubject({ monthly: 0, annualy: 0, savings: 0 });
    }

    setProductAndPlan = (plan, product) => {
        if (product) {
            this.selectedProductSubject.next(product);
        } else {
            product = this.selectedProductSubject.getValue();
        }
        if (plan) {
            this.selectedPlanSubject.next(plan);
        } else {
            plan = this.selectedPlanSubject.getValue();
        }

        if (this.productSubject.getValue().length === 0) return;

        let xProduct = this.productSubject.getValue().find((e) => product === e.name);

        let xPlanMonthly = xProduct.subscription_plans.find((e) => 'SubscriptionPlanTypeMonthly' === e.type);
        let xPlanYearly = xProduct.subscription_plans.find((e) => 'SubscriptionPlanTypeYearly' === e.type);

        if (!xPlanYearly) {
            this.savings.next(0);
        } else {
            let savings = (((xPlanMonthly.price * 12 - xPlanYearly.price) * 100) / xPlanYearly.price).toFixed(2);
            this.savings.next(savings);
        }
        this.setSelectedMTR();
    };

    setSelectedMTR = (mtr) => {
        if (mtr) {
            this.selectedMTR.next(mtr);
        } else {
            mtr = this.selectedMTR.getValue();
        }

        if (this.productSubject.getValue().length === 0 || this.overageSubject.getValue().length === 0) return;

        let xProduct = this.productSubject.getValue().find((e) => this.selectedProductSubject.getValue() === e.name);
        let xPlanMonthly = xProduct.subscription_plans.find((e) => 'SubscriptionPlanTypeMonthly' === e.type);
        let xPlanYearly = xProduct.subscription_plans.find((e) => 'SubscriptionPlanTypeYearly' === e.type);

        if (!xPlanYearly) {
            this.billing.next({ monthly: 0, annualy: 0, savings: this.savings.getValue() });
        } else {
            const overage = this.overageSubject.getValue();
            let selected = false;
            for (let i = 0; i < overage.length; i++) {
                if (overage[i].tier === xProduct.tier) {
                    const xMTR = xProduct.features.find((e) => e.name === 'MTR-Limit');
                    if (mtr * 1000 >= overage[i].lower_bound && mtr * 1000 <= overage[i].upper_bound) {
                        let rMTR = mtr * 1000 - xMTR.value;
                        const x = Math.round(rMTR / overage[i].unit) * overage[i].price;
                        const m = xPlanMonthly.price + x;
                        const y = Math.round(xPlanYearly.price / 12) + x;
                        this.billing.next({ monthly: m, annualy: y, savings: this.savings.getValue() });
                        selected = true;
                    }
                }
            }
            if (!selected) {
                this.billing.next({
                    monthly: xPlanMonthly.price,
                    annualy: Math.round(xPlanYearly.price / 12),
                    savings: this.savings.getValue(),
                });
            }
        }
    };

    onProductSuccess = (response) => {
        this.productSubject.next(response.data);
        this.setProductAndPlan();
    };
    onOverageSuccess = (response) => {
        this.overageSubject.next(response.data);
    };

    getProducts = () => {
        let data = [
            {
                id: 1,
                name: 'Build',
                tier: 1,
                subscription_plans: [
                    {
                        id: 1,
                        type: 'SubscriptionPlanTypeMonthly',
                        price: 0,
                    },
                    {
                        id: 1,
                        type: 'SubscriptionPlanTypeYearly',
                        price: 0,
                    },
                ],
                features: [
                    {
                        id: 1,
                        name: 'MTR-Limit',
                        value: 10000,
                    },
                    {
                        id: 1,
                        name: 'Collaborator-Num',
                        value: 1,
                    },
                ],
            },
            {
                id: 2,
                name: 'Analyze',
                tier: 2,
                subscription_plans: [
                    {
                        id: 1,
                        type: 'SubscriptionPlanTypeMonthly',
                        price: 99,
                    },
                    {
                        id: 1,
                        type: 'SubscriptionPlanTypeYearly',
                        price: 84,
                    },
                ],
                features: [
                    {
                        id: 1,
                        name: 'MTR-Limit',
                        value: 20000,
                    },
                    {
                        id: 1,
                        name: 'Collaborator-Num',
                        value: 5,
                    },
                ],
            },
            {
                id: 3,
                name: 'Business',
                tier: 3,
                subscription_plans: [
                    {
                        id: 1,
                        type: 'SubscriptionPlanTypeMonthly',
                        price: 199,
                    },
                    {
                        id: 1,
                        type: 'SubscriptionPlanTypeYearly',
                        price: 169,
                    },
                ],
                features: [
                    {
                        id: 1,
                        name: 'MTR-Limit',
                        value: 100000,
                    },
                    {
                        id: 1,
                        name: 'Collaborator-Num',
                        value: 10,
                    },
                ],
            },
        ];
        let res = { data: data };
        this.onProductSuccess(res);

        // Axios.get('https://api.purchasefly.com/customer/api/v1/subscription/listproducts')
        //     .then(this.onProductSuccess)
        //     .catch(this.error);
    };

    getOverages = () => {
        let data = [
            {
                id: 1,
                lower_bound: 10000,
                upper_bound: 49999,
                unit: 1000,
                price: 6,
                tier: 2,
            },
            {
                id: 1,
                lower_bound: 50000,
                upper_bound: 500000,
                unit: 1000,
                price: 5,
                tier: 2,
            },
            {
                id: 1,
                lower_bound: 10000,
                upper_bound: 49999,
                unit: 1000,
                price: 5,
                tier: 3,
            },
            {
                id: 1,
                lower_bound: 50000,
                upper_bound: 99999,
                unit: 1000,
                price: 4,
                tier: 3,
            },
            {
                id: 1,
                lower_bound: 100000,
                upper_bound: 500000,
                unit: 1000,
                price: 3,
                tier: 3,
            },
        ];
        let res = { data: data };
        this.onOverageSuccess(res);
        // Axios.get('https://api.purchasefly.com/customer/api/v1/subscription/listoverages')
        //     .then(this.onOverageSuccess)
        //     .catch(this.error);
    };

    //the error function with deal with sending an error through the subject
    error = (error) => {
        console.log(error);
        //this.productSubject.error(error)
    };

    //the get function to return the subject. It allows the component to subscribe as Observer.
    getProductSubject = () => {
        return this.productSubject;
    };
    getOverageSubject = () => {
        return this.overageSubject;
    };
    getMTRSubject = () => {
        return this.selectedMTR;
    };
    getSavings = () => {
        return this.savings;
    };
    getBilling = () => {
        return this.billing;
    };
}
