import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Space from '../Shared/ComponentUI/Spcae';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { ComponentIcon } from '../Shared/ComponentUI/ComponentIcon';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const features = [
    {
        title: 'Product Groups',
        description: 'A Product Group represents features or content that a user is "entitled" to.',
    },
    {
        title: 'Packages',
        description: 'Packages are the selection of products that are offered to your app’s user.',
    },
    {
        title: 'Products',
        description: 'Product identifiers configured for your app in App Store Connect and Play Store',
    },
];

export class ConfigureProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type="flex" style={{ marginBottom: 150 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)} style={{ backgroundColor: '#000000' }}>
                    <Space height={50} />
                    <ComponentText
                        text="Configure your products,<br/>the way you want"
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={50}
                        color={Colors.white}
                    />
                    <Space height={56} />

                    <img alt="" src={Images.configureProduct} style={{ width: '75%' }} />
                    <Space height={60} />
                    <Row type={'flex'} justify={'center'}>
                        {features.map((e, i) => (
                            <ComponentPrepareItem key={i} title={e.title} description={e.description} />
                        ))}
                    </Row>
                    <Space height={80} />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ backgroundColor: '#000000', marginBottom: 80 }}>
                <Space height={60} />
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text="Configure your products, the way you want"
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={Colors.white}
                    />
                </div>
                <Space height={40} />

                <img alt="" src={Images.configureProductMobile} style={{ width: '90%' }} />
                <Space height={60} />
            </Row>
        );
    }
}

function ComponentPrepareItem({ title, description }) {
    return (
        <Row style={{ marginLeft: 50, marginRight: 50, width: 200 }}>
            <Space height={16} />
            <Row type={'flex'} align={'middle'} justify={'center'}>
                <ComponentText
                    text={title}
                    align="center"
                    font={fonts.SFProDisplaySemiBold}
                    size={25}
                    color={Colors.white}
                />
                <Space width={12} />
                <ComponentIcon type={'arrow-right-white'} />
            </Row>
            <Space height={16} />
            <ComponentText
                text={description}
                align="center"
                font={fonts.SFProDisplayRegular}
                size={16}
                color={Colors.white}
            />
        </Row>
    );
}
