import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from '../Home/Home';
import SubscriptionManagement from '../SubscriptionManagement/SubscriptionManagement';
import ChartsMetrics from '../ChartsMetrics/ChartsMetrics';
import PlansProducts from '../PlansProducts/PlansProducts';
import CustomerProfile from '../CustomerProfile/CustomerProfile';
import { paths } from '../Shared/Routes';
import PricingPlans from '../PricingPlans/PricingPlans';
import { AboutUs } from '../AboutUs/AboutUs';
import { ContactUs } from '../ContactUs/ContactUs';
import Documentation from '../Documentation/Documentation';
import TermsPolicy from '../TermsPolicy/TermsPolicy';

class MainWindow extends Component {
    render() {
        const location = this.props.location;
        return (
            <div>
                <Switch>
                    <Route location={location} path={paths.Home} component={Home} />
                    <Route location={location} path={paths.SubscriptionManagement} component={SubscriptionManagement} />
                    <Route location={location} path={paths.ChartsMetrics} component={ChartsMetrics} />
                    <Route location={location} path={paths.PlansProduct} component={PlansProducts} />
                    <Route location={location} path={paths.CustomerProfile} component={CustomerProfile} />
                    <Route location={location} path={paths.PricingPlans} component={PricingPlans} />
                    <Route location={location} path={paths.AboutUs} component={AboutUs} />
                    <Route location={location} path={paths.ContactUs} component={ContactUs} />
                    <Route location={location} path={paths.Documentation} component={Documentation} />
                    <Route location={location} path={paths.TermsPolicy} component={TermsPolicy} />
                    {/*<Route location={location} path="/terms" component={TermsConditionPolicy} />*/}

                    {/*_add_from_here*/}
                    <Route location={location} component={Home} />
                </Switch>
            </div>
        );
    }
}

MainWindow.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default MainWindow;
