import React from 'react';
import { Form, Input } from 'antd';
import './FormComponent.css';
import TextArea from 'antd/lib/input/TextArea';

export class InputComponent extends React.Component {
    render() {
        const { getFieldDecorator } = this.props.form;
        const { name, label, placeholder, rules, extra } = this.props.values;
        const { textArea } = this.props;

        return (
            <Form.Item className="no-bottom-margin" label={label} extra={extra}>
                {getFieldDecorator(name, {
                    rules: rules,
                })(
                    textArea === true ? (
                        <TextArea rows={4} placeholder={placeholder} />
                    ) : (
                        <Input placeholder={placeholder} />
                    )
                )}
            </Form.Item>
        );
    }
}
