import React from 'react';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Col, Row } from 'antd';
import Images from '../../images/images';
import Space from '../Shared/ComponentUI/Spcae';
import { Colors } from '../../css/Colors';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Constant change requires<br/>dynamic views';
const contents = [
    {
        icon: Images.dynamicView1,
        title: 'Real-time smart tracking',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
    },
    {
        icon: Images.dynamicView2,
        title: 'CustomerProfile Movements',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
    },
    {
        icon: Images.dynamicView3,
        title: 'Offer Management',
        description: 'In-app purchases are content or subscriptions that you can buy in apps on your iOS or Android.',
    },
];
export class DynamicViews extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };
    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row
                type={'flex'}
                align={'middle'}
                justify={'space-between'}
                style={{ backgroundColor: 'var(--pale-grey-five)', marginTop: 84, marginBottom: 84 }}
            >
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Space height={84} />
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={50}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                    <Space height={84} />
                    <Row type={'flex'} align={'middle'} justify={'space-between'} gutter={40}>
                        {contents.map((e, i) => (
                            <Col key={i} span={8}>
                                <img src={e.icon} width={100} alt={''} />
                                <Space height={30} />
                                <ComponentText
                                    text={e.title}
                                    font={fonts.SFProDisplaySemiBold}
                                    size={22}
                                    color={Colors.dark}
                                    align={'center'}
                                />
                                <Space height={16} />
                                <ComponentText
                                    text={e.description}
                                    font={fonts.SFProDisplayRegular}
                                    size={20}
                                    color={Colors.slate_grey}
                                    align={'center'}
                                />
                            </Col>
                        ))}
                    </Row>
                    <Space height={84} />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ backgroundColor: 'var(--pale-grey-five)', marginTop: 80, marginBottom: 80 }}>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title.replace('<br/>', ' ')}
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                </div>
                <Space height={84} />
                {contents.map((e, i) => (
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <img src={e.icon} width={100} alt={''} />
                        <Space height={30} />
                        <ComponentText
                            text={e.title}
                            font={fonts.SFProDisplaySemiBold}
                            size={22}
                            color={Colors.dark}
                            align={'center'}
                        />
                        <Space height={16} />
                        <ComponentText
                            text={e.description}
                            font={fonts.SFProDisplayRegular}
                            size={20}
                            color={Colors.slate_grey}
                            align={'center'}
                        />
                        <Space height={60} />
                    </div>
                ))}
            </Row>
        );
    }
}
