import React from 'react';
import Terms from './Terms';
import Privacyplicy from './PrivacyPolicy';
import { Col, Divider, Menu, Row, Tabs } from 'antd';
import './TermsPolicy.css';
import { Footer } from '../Shared/Menus/Footer';
import { TopBar } from '../Shared/Menus/TopBar';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';
import Space from '../Shared/ComponentUI/Spcae';
import { BottomMenu } from '../Shared/Menus/BottomMenu';

const { TabPane } = Tabs;

class TermsPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    getSelectedMenu = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('menu');
        return token ? token : '0';
    };

    onClick = ({ key }) => {
        this.addQuery({ menu: key });
    };

    addQuery = (query) => {
        this.props.history.push({
            pathname: '',
            search: '?' + new URLSearchParams(query).toString(),
        });
    };

    render() {
        return (
            <div>
                <TopBar />
                <Space height={50} />
                <Row type="flex" align={'middle'}>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                        <div className="terms-policy">
                            <div className="terms-left-menu">
                                <Col span={4}>
                                    <Menu
                                        className="terms-policy"
                                        mode="inline"
                                        onClick={this.onClick}
                                        defaultSelectedKeys={[this.getSelectedMenu()]}
                                        selectedKeys={[this.getSelectedMenu()]}
                                        style={{ width: 185 }}
                                    >
                                        <Menu.Item key="0">Terms & Condition</Menu.Item>
                                        <Menu.Item key="1">Privacy Policy</Menu.Item>
                                    </Menu>
                                </Col>
                            </div>
                            <Row type="flex">
                                <Col span={1}>
                                    <Divider style={{ height: 600, width: '1.7px' }} type="vertical" />
                                </Col>
                                <Col span={23} style={{ paddingLeft: 20 }}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        tabPosition={'bottom'}
                                        animated={false}
                                        activeKey={this.getSelectedMenu()}
                                    >
                                        <TabPane tab={`Terms & Condition`} key={0}>
                                            <Terms />
                                        </TabPane>
                                        <TabPane tab={`Privacy Policy`} key={1}>
                                            <Privacyplicy />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                </Row>
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}

export default TermsPolicy;
