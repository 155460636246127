import React from 'react';
import { Row, Tabs } from 'antd';
import { CodeBlock, dracula } from 'react-code-blocks';
import './CodeSnippet.css';
import { ComponentText } from '../ComponentUI/ComponentText';
import { fonts } from '../../../fonts/fonts';
import { Colors } from '../../../css/Colors';

const { TabPane } = Tabs;

export class CodeSnippet extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedTab: 'swift' };
    }

    render() {
        const { width } = this.props;
        return (
            <div>
                <Row
                    type="flex"
                    justify={'space-between'}
                    style={{ width: width ? width : '75%', margin: 'auto', textAlign: 'left' }}
                >
                    {this.prepareTabButton('Swift', 'swift')}
                    {this.prepareTabButton('Kotlin', 'kotlin')}
                    {this.prepareTabButton('Java', 'java')}
                </Row>
                <div
                    style={{ width: width ? width : '75%', margin: 'auto', textAlign: 'left' }}
                    className="card-container"
                >
                    <Tabs
                        type="card"
                        tabPosition={'bottom'}
                        defaultActiveKey={'swift'}
                        activeKey={this.state.selectedTab}
                    >
                        <TabPane tab="Swift" key={'swift'}>
                            <CodeBlock
                                text={
                                    'PFPurchase.shared.make(with: monthlyProduct) \n' +
                                    '   { (transaction, groupsByName, error, cancelled) in\n' +
                                    '    if let groupsByName = groupsByName {\n' +
                                    '        let group = groupsByName["PREMIUM"]\n' +
                                    '        if (group.isActive) {\n' +
                                    '            // Purchase is successful\n' +
                                    '        }\n' +
                                    '    }\n' +
                                    '}\n\n\n'
                                }
                                language={'swift'}
                                showLineNumbers={true}
                                theme={dracula}
                            />
                        </TabPane>
                        <TabPane tab="Kotlin" key={'kotlin'}>
                            <CodeBlock
                                text={
                                    'PFPurchase\n' +
                                    '   .sharedInstance\n' +
                                    '   .purchase(this, product, object : MakePurchaseListener{  \n' +
                                    '       override fun onCompleted(purchase: Product, it: ResponseGroups) {}  \n' +
                                    '       override fun onError(error: PurchasesError, userCancelled: Boolean) {}  \n' +
                                    '   });\n\n\n\n\n\n'
                                }
                                language={'kotlin'}
                                showLineNumbers={true}
                                theme={dracula}
                            />
                        </TabPane>
                        <TabPane tab="Java" key={'java'}>
                            <CodeBlock
                                text={
                                    'PFPurchase\n' +
                                    '   .sharedInstance.purchase(\n' +
                                    '       this, \n' +
                                    '       product, \n' +
                                    '       new MakePurchaseListener() {  \n' +
                                    '           @Override  \n' +
                                    '           public void onCompleted(@NotNull Product purchase, @NotNull ResponseGroups it) {  }  \n' +
                                    '           @Override  \n' +
                                    '           public void onError(@NotNull PurchasesError error, boolean userCancelled) {  }  \n' +
                                    '   });\n\n'
                                }
                                language={'java'}
                                showLineNumbers={true}
                                theme={dracula}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }

    prepareTabButton = (name, key) => {
        let isSelected = key === this.state.selectedTab;
        return (
            <div
                className={isSelected ? 'tab-button-selected' : 'tab-button'}
                onClick={() => this.setState({ selectedTab: key })}
            >
                <ComponentText
                    text={name}
                    align="center"
                    font={fonts.SFProDisplayRegular}
                    size={16}
                    color={isSelected ? Colors.white : Colors.slate_grey}
                />
            </div>
        );
    };
}
