import React from 'react';
import { Col, Row } from 'antd';
import Images from '../../images/images';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import Space from '../Shared/ComponentUI/Spcae';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';
import { links } from '../Shared/Routes';

export class WelcomeContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        return (
            <Row>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)} className={'welcome-content'}>
                    <Row>
                        <Col span={3} />
                        <Col span={18}>
                            <span>
                                <span className={'semi-bold-20-black'}>Welcome to PurchaseFly</span>
                                <br />
                                <br />
                                <span role={'img'} aria-label={''}>
                                    👋
                                </span>
                                <span className={'semi-bold-20-battleship-grey'}>
                                    Welcome, it's great to have you here.
                                </span>
                                <br />
                                <br />
                                <span className={'regular-16-charcoal-grey'}>
                                    We know that first impressions are important, so we've populated your new site with
                                    some initial getting started posts that will help you get familiar with everything
                                    in no time. This is the first one!
                                </span>
                                <br />
                                <br />
                            </span>
                            <img src={Images.documentation} width={'100%'} alt={''} />
                            <span>
                                <br /> <br />
                                <span className={'semi-bold-16-black'}>A few things you should know upfront:</span>
                                <br />
                                <br />
                                <ol type="1">
                                    <li>
                                        {' '}
                                        <span className={'regular-16-charcoal-grey'}>
                                            Ghost is designed for ambitious, professional publishers who want to
                                            actively build a business around their content. That's who it works best
                                            for.
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        {' '}
                                        <span className={'regular-16-charcoal-grey'}>
                                            The entire platform can be modified and customised to suit your needs. It's
                                            very powerful, but does require some knowledge of code. Ghost is not
                                            necessarily a good platform for beginners or people who just want a simple
                                            personal blog.
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        {' '}
                                        <span className={'regular-16-charcoal-grey'}>
                                            For the best experience we recommend downloading the Ghost Desktop App for
                                            your computer, which is the best way to access your Ghost site on a desktop
                                            device.
                                            <br />
                                            Ghost is made by an independent non-profit organisation called the Ghost
                                            Foundation. We are 100% self funded by revenue from our Ghost(Pro) service,
                                            and every penny we make is re-invested into funding further development of
                                            free, open source technology for modern publishing.
                                        </span>
                                    </li>
                                </ol>
                                <br />
                                <span className={'regular-16-charcoal-grey'}>
                                    The version of Ghost you are looking at right now would not have been made possible
                                    without generous contributions from the open source community.
                                </span>
                                <br />
                            </span>
                            <span>
                                <br />
                                <br />
                                <span className={'semi-bold-20-black'}>Start building with PurchaseFly</span>
                                <br />
                                <br />

                                <ul>
                                    <li>
                                        {' '}
                                        <span className={'regular-16-charcoal-grey'}>
                                            Fetch products, make purchases, and check subscription status with our
                                            open-source,{' '}
                                            <a href={links.SDK}>
                                                <span className={'medium-16-deep-sky-blue'}>native SDKs</span>
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <span className={'regular-16-charcoal-grey'}>
                                            <span role={'img'} aria-label={''}>
                                                🛒
                                            </span>{' '}
                                            Host and{' '}
                                            <a href={links.Configure}>
                                                <span className={'medium-16-deep-sky-blue'}>configure products</span>
                                            </a>{' '}
                                            remotely from our dashboard
                                        </span>
                                    </li>
                                    <li>
                                        <span className={'regular-16-charcoal-grey'}>
                                            <span role={'img'} aria-label={''}>
                                                📊
                                            </span>{' '}
                                            <a href={links.Analyze}>
                                                <span className={'medium-16-deep-sky-blue'}>Analyze</span>
                                            </a>{' '}
                                            key metrics on the health of your mobile subscription business in one place
                                        </span>
                                    </li>
                                    <li>
                                        <span className={'regular-16-charcoal-grey'}>
                                            <span role={'img'} aria-label={''}>
                                                😄
                                            </span>{' '}
                                            See customer-level transaction histories, lifetime value, and{' '}
                                            <a href={links.Subscription}>
                                                <span className={'medium-16-deep-sky-blue'}>
                                                    grant promotional subscriptions
                                                </span>
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <span className={'regular-16-charcoal-grey'}>
                                            <span role={'img'} aria-label={''}>
                                                🔀
                                            </span>{' '}
                                            Get notified of realtime events through{' '}
                                            <a href={links.Webhooks}>
                                                <span className={'medium-16-deep-sky-blue'}>webhooks</span>
                                            </a>{' '}
                                            and integrations directly into your existing systems
                                        </span>
                                    </li>
                                </ul>
                            </span>
                        </Col>
                        <Col span={3} />
                    </Row>
                    <Space height={50} />
                    <Row type={'flex'} justify={'center'}>
                        <ComponentButton
                            text={'Get started with first step'}
                            css={'deep-sky-blue-and-white'}
                            iconRight={'arrow-right-white'}
                            height={50}
                        />
                    </Row>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
}
