import React from 'react';
import { Col, Row } from 'antd';
import Space from '../ComponentUI/Spcae';
import { LogoDocumentation } from './LogoDocumentation';
import { getDecreasingSpan, getIncreasingSpan } from '../Col4';

export class TopBarDocumentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        return (
            <Row type={'flex'}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Space height={30} />
                    <Row type="flex" justify="space-between" align="middle" style={{ height: 45 }}>
                        <LogoDocumentation />
                        <Row type="flex" justify="end" align="middle" style={{ height: 45 }}>
                            <a href={'/guide'}>
                                <span className="top-bar-link-doc">Guide</span>
                            </a>
                            <Space width={24} />
                            <a href={'/blog'}>
                                <span className="top-bar-link-doc">Blog</span>
                            </a>
                            <Space width={24} />
                            <a href={'/home'}>
                                <span className="top-bar-link-doc">Go to Purchasefly.com</span>
                            </a>
                        </Row>
                    </Row>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
}
