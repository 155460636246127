import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import Space from '../Shared/ComponentUI/Spcae';

const title = 'Purchasefly Privacy Policy';
const subText = 'Current version published on 1/08/2022.';
class Privacyplicy extends React.Component {
    render() {
        return (
            <div style={{ textAlign: 'left' }}>
                <ComponentText
                    text={title}
                    font={fonts.SFProDisplayBold}
                    size={36}
                    color={'var(--black-four)'}
                    align={'left'}
                />
                <Space height={10} />

                <ComponentText
                    text={subText}
                    font={fonts.SFProDisplayRegular}
                    size={16}
                    color={'var(--slate-grey)'}
                    align={'left'}
                />
                <Space height={50} />

                {ReactHtmlParser(html)}
            </div>
        );
    }
}

export default Privacyplicy;

const html = `<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Your privacy is important to us and we are committed to protecting your personal data. We want you to feel confident when using the PurchaseFly service and comfortable with how your content is used.&nbsp;</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from&nbsp;</span><a
href="https://www.purchasefly.com/terms" style="text-decoration:none;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://www.purchasefly.com/terms</span></a><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By using this service, you agree to allow us to collect and process information as described below. If you have any questions or believe that content has been uploaded into PurchaseFly that shouldn&rsquo;t have been, please contact us at&nbsp;</span><a
href="mailto:contact@purchasefly.com" style="text-decoration:none;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@purchasefly.com</span></a><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">There are two different kinds of data we touch:&nbsp;</span><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">your personal data</span><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;as the developer and the&nbsp;</span><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">data of your customers</span><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;who use your app that uses PurchaseFly. We have different policies for each and it&rsquo;s worth breaking down:</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:14.05pt;"><span
style="font-size:13.999999999999998pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Your Personal Data</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For your personal data as a developer, we act in the role of a &ldquo;controller&rdquo; and in our privacy policy we lay out a fairly standard set of policies. We aren&rsquo;t selling your personal data but we may use it to send you emails, track you with our own analytics tools etc.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:14.05pt;"><span
style="font-size:13.999999999999998pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Your Revenue and Customer Data</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Your customer data, which would, by association, include information about your business, are under a completely different set of policies. In GDPR terms, we are a &ldquo;processor&rdquo; here. This data is probably why you are here in the first place. Two important points:</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We will never share information about your app outside of PurchaseFly</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The data about your revenue and your users stays within PurchaseFly. We utilize 3rd party services for hosting, such as AWS, but that data is encrypted and unavailable to those providers.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We will never send identifiable information about your users to 3rd parties without your consent</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">With the exception of opt-in integrations, we won&rsquo;t send information about your subscribers to any 3rd parties. We don&rsquo;t want the liability of any downstream processors having issues and compromising your data. We want to keep it with us securely.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We will never sell your data</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We&rsquo;re in the business of making money off of you making money. We do not sell your data nor do we ever intend to.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The data you send us is governed by your privacy policy</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The user data you send to us should be disclosed in your own privacy policy. If you use a custom&nbsp;</span><a
href="https://docs.revenuecat.com/docs/user-ids" style="text-decoration:none;"><span
style="font-size:12pt;color:#0000ee;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">App User ID</span></a><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, that may contain personal information, be sure to disclose such sharing to your customers in your own privacy policy.</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:14.95pt;"><span
style="font-size:18pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Types of Data Collected</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:13.999999999999998pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Personal Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally identifiable information may include, but is not limited to:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;">
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Email address</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">First name and last name</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookies and Usage Data</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Address</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Phone number</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Personal description and photograph</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Login and password details</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Information about your apps: app name, bundle ID, App Store shared secret key, information about subscription and other in-app purchases</span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br><br></span>
</p></li>
</ul><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:13.999999999999998pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usage Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may also collect information how the Service is accessed and used (&quot;Usage Data&quot;). This Usage Data may include information such as your computer&#39;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:13.999999999999998pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tracking Cookies Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We use cookies and similar tracking technologies (such as device-IDs, in-app codes, pixel tags or web beacons) to track the activity on our Service and hold certain information.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookies and similar technologies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Examples of Cookies we use:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;">
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Session Cookies. We use Session Cookies to operate our Service.</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Security Cookies. We use Security Cookies for security purposes.</span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br><br></span>
</p></li>
</ul><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:13.999999999999998pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End Users data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may collect information sent by a User&rsquo;s End Users via our SDK. This information may include, but is not limited to:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;">
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Country</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Locale</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Language</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Time zone</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Current IP address</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Mobile platform</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Device model</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">OS version installed on device</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last seen &ndash; the last time a user used your app</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">App Store receipts</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Attribution data, identifier for advertisers</span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br><br></span>
</p></li>
</ul><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Use of Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PurchaseFly uses the collected data for various purposes:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;">
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To provide and maintain our Service</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To notify you about changes to our Service</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To allow you to participate in interactive features of our Service when you choose to do so</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To provide customer support</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To gather analysis or valuable information so that we can improve our Service</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To monitor the usage of our Service</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To detect, prevent and address technical issues</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</span>
</p></li>
</ul><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:16.1pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Retention of Data</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PurchaseFly will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PurchaseFly will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Transfer Of Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Your information, including Personal Data, may be transferred to &ndash; and maintained on &ndash; computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PurchaseFly will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Disclosure Of Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:18pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Disclosure for Law Enforcement</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Under certain circumstances, PurchaseFly may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:18pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Legal Requirements</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PurchaseFly may disclose your Personal Data in the good faith belief that such action is necessary to:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;">
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To comply with a legal obligation</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To protect and defend the rights or property of PurchaseFly</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To prevent or investigate possible wrongdoing in connection with the Service</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To protect the personal safety of users of the Service or the public</span>
</p></li>
<li dir="ltr"
style="list-style-type:disc;font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -7pt;padding-left: 7pt;">
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To protect against legal liability</span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
        style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br><br></span>
</p></li>
</ul><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Security Of Data</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All information you pass to us is stored on secure servers. Any payments are encrypted using SSL technology.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span>
</p><br/><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:14.95pt;"><span
style="font-size:18pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Payments</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The payment processors we work with is Stripe. Their Privacy Policy can be viewed at&nbsp;</span><a
href="https://stripe.com/privacy" style="text-decoration:none;"><span
style="font-size:12pt;color:#3884ff;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://stripe.com/privacy</span></a><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:18pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other services</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may use other Service Providers to improve the quality of our Service.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Links To Other Sites</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party&#39;s site. We strongly advise you to review the Privacy Policy of every site you visit.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Children&#39;s Privacy</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our Service does not address anyone under the age of 18 (&quot;Children&quot;).</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Changes To This Privacy Policy</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
style="font-size:24pt;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Contact Us</span>
</p><br/><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you have any questions about this Privacy Policy, please contact us:&nbsp;</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PurchaseFly Inc.</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">123 E San Carlos St, Unit #20, SAN JOSE, CA 95112</span>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><a href="mailto:info@purchasefly.com"
                                                                       style="text-decoration:none;"><span
style="font-size:12pt;color:#3884ff;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">info@purchasefly.com</span></a>
</p><p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:12pt;"><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
style="font-size:12pt;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br><br></span>
</p>`;
