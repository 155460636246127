import React from 'react';
import { Button, Row } from 'antd';
import { ComponentIcon } from './ComponentIcon';
import Space from './Spcae';
import Images from '../../../images/images';

export class ComponentButton extends React.Component {
    prepareIcon = (antd, icon, size) => {
        return <ComponentIcon antd={antd} type={icon} />;
        //return <img src={getImage(icon)} alt={""} height={20} style={{paddingTop:2}} color={Colors.cloudy_blue}/>
    };

    render() {
        const {
            css,
            text,
            iconLeft,
            iconRight,
            icon,
            handleClick,
            name,
            antd,
            padding,
            iconSize,
            iconSizeRight,
            height,
            width,
            htmlType,
            spaceWidth,
        } = this.props;
        const style = { padding: padding + 'px', height: height + 'px', width: width + 'px' };

        return (
            <Button
                className={css}
                style={style}
                key={name}
                onClick={handleClick}
                htmlType={htmlType ? htmlType : null}
            >
                <Row type="flex" align="middle" justify="center">
                    {iconLeft ? this.prepareIcon(antd, iconLeft, iconSize) : null}
                    <Space width={iconLeft ? spaceWidth || '10' : '0'} />
                    <div>{text}</div>
                    {icon ? this.prepareIcon(antd, icon, iconSize) : null}
                    <Space width={iconRight ? spaceWidth || '10' : '0'} />
                    {iconRight ? this.prepareIcon(antd, iconRight, iconSizeRight) : null}
                </Row>
            </Button>
        );
    }
}

function getImage(type) {
    switch (type) {
        case 'arrow-right':
            return Images.arrow_right_white;
        case 'arrow-right-white':
            return Images.arrow_right_white;
        case 'arrow-up':
            return Images.arrow_up;
        case 'top':
            return Images.ic_top;
        default:
            return '';
    }
}
