import React from 'react';
import './CustomeProfile.css';
import { Banner } from './Banner';

import { TopBar } from '../Shared/Menus/TopBar';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { Footer } from '../Shared/Menus/Footer';
import { FeaturesPurchaseFly } from '../Shared/FeaturesPurchaseFly';
import { DynamicViews } from './DynamicViews';
import { Features } from '../SubscriptionManagement/Features';
import Images from '../../images/images';
import { ComprehensiveTimeLine } from './ComprehensiveTimeLine';
import { TrustedCustomer } from "../Home/TrustedCustomer";

class CustomerProfile extends React.Component {
    render() {
        return (
            <div>
                <TopBar />
                <Banner />
                <ComprehensiveTimeLine />
                <Features contents={features} />
                <DynamicViews />
                <FeaturesPurchaseFly title={'There’s more for you'} index={0} />
                <TrustedCustomer/>
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}
export default CustomerProfile;

const features = [
    {
        image: Images.monitorSubscription,
        title: 'Monitor subscription movements',
        description:
            'Understand both MRR and usage growth trends over time and organized by customer—drill down to see individual movements for each subscription.',
        link: '',
        left: false,
        spans: [13, 11],
        space: 160,
    },
    {
        image: Images.offerTrial,
        title: 'Offer exclusive trial promotions',
        description:
            'Is a certain user non-paying, in trial or has active subscription? Forget about UserDefaults and Keychain. Apphud will keep user state up-to-date using Apple servers.',
        link: '',
        left: true,
        spans: [11, 13],
        space: 160,
        shadow: true,
    },
];
