import React from 'react';
import { Col, Divider, Dropdown, Menu, Row } from 'antd';
import { Logo } from './Logo';

import Space from '../ComponentUI/Spcae';
import { ComponentText } from '../ComponentUI/ComponentText';
import { Colors } from '../../../css/Colors';
import { fonts } from '../../../fonts/fonts';
import Images from '../../../images/images';
import { paths } from '../Routes';
import { getDecreasingSpan, getIncreasingSpan } from '../Col4';

const menus = [
    [
        { title: 'Products', to: 'products' },
        { title: 'Features', to: 'feature' },
        { title: 'Pricing', to: 'pricing' },
        { title: 'Integrations', to: 'integrations' },
    ],
    [
        { title: 'Developers', to: 'developers' },
        { title: 'Documentation', to: paths.Documentation },
        { title: 'API Reference', to: 'pricing' },
        { title: 'Get Support', to: 'get' },
        { title: 'Changelog', to: 'change' },
    ],
    [
        { title: 'Company', to: 'products' },
        { title: 'About Purchasefly', to: paths.AboutUs },
        { title: 'Press Coverages', to: 'pricing' },
        { title: 'FAQ', to: 'intrgrations' },
        { title: 'News', to: 'intrgrations' },
    ],
    [
        { title: 'Contact', to: '' },
        { title: 'Login', to: 'login' },
        { title: 'Signup', to: 'sign-up' },
        { title: 'Contact us', to: paths.ContactUs },
    ],
];
export class BottomMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        const { details } = this.props;
        return (
            <Row>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    {details ? <MenuDetails /> : <MenuMinimum />}
                    {details ? (
                        <Row style={{ marginBottom: 0, marginTop: 30 }}>
                            <Divider />
                        </Row>
                    ) : null}
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row>
                <Row type={'flex'} justify={'start'} style={{ paddingLeft: 24 }}>
                    <Logo />
                </Row>
                <Space height={40} />
                <Row type={'flex'}>
                    <Col span={12}>
                        <MenuItemMobile item={menus[0]} />
                    </Col>
                    <Col span={12}>
                        <MenuItemMobile item={menus[1]} />
                    </Col>
                </Row>
                <Space height={40} />
                <Row type={'flex'}>
                    <Col span={12}>
                        <MenuItemMobile item={menus[2]} />
                    </Col>
                    <Col span={12}>
                        <MenuItemMobile item={menus[3]} />
                    </Col>
                </Row>
                <Space height={40} />
                <Divider />
                <Row type={'flex'} justify={'start'} style={{ paddingLeft: 24 }}>
                    <Col span={12}>
                        <ComponentText
                            text={'Language'}
                            size={15}
                            font={fonts.SFProDisplayBold}
                            color={Colors.black_two}
                            align="left"
                        />
                        <Space height={14} />
                        <Dropdown overlay={menu} placement="bottomLeft" className={'select-language'}>
                            <span>
                                <Row type={'flex'} justify={'start'}>
                                    {' '}
                                    English{' '}
                                </Row>
                            </span>
                        </Dropdown>
                    </Col>
                    <Col span={12}>
                        <ComponentText
                            text={'Follow us'}
                            size={15}
                            font={fonts.SFProDisplayBold}
                            color={Colors.black_two}
                            align="left"
                        />
                        <Space height={14} />
                        <Row type={'flex'} align={'middle'} className={'follow'}>
                            <img alt="" src={Images.facebook} width={25} />
                            <Space width={12} />
                            <img alt="" src={Images.twitter} width={25} />
                            <Space width={12} />
                            <img alt="" src={Images.linkedin} width={25} />
                        </Row>
                    </Col>
                </Row>
                <Space height={24} />
                <Divider />
            </Row>
        );
    }
}
function MenuItemMobile({ item }) {
    return (
        <Row style={{ paddingLeft: 24, paddingRight: 24 }}>
            {item.map((f, j) =>
                j === 0 ? (
                    <ComponentText
                        key={j}
                        text={f.title}
                        size={15}
                        font={fonts.SFProDisplayBold}
                        color={Colors.black_two}
                        align="left"
                    />
                ) : (
                    <Row key={j} align="middle" justify="start" type="flex" style={{ marginTop: 12 }}>
                        <a href={f.to}>
                            <span className="bottom-menu"> {f.title}</span>
                        </a>
                    </Row>
                )
            )}
        </Row>
    );
}

function MenuDetails() {
    return (
        <Row type="flex" justify="start">
            {menus.map((e, i) => (
                <Col key={i} span={5}>
                    {e.map((f, j) =>
                        j === 0 ? (
                            <ComponentText
                                key={j + i}
                                text={f.title}
                                size={15}
                                font={fonts.SFProDisplayBold}
                                color={Colors.black_two}
                                align="left"
                            />
                        ) : (
                            <Row key={j + i} align="middle" justify="start" type="flex" style={{ marginTop: 13 }}>
                                <a href={f.to}>
                                    <span className="bottom-menu"> {f.title}</span>
                                </a>
                            </Row>
                        )
                    )}
                </Col>
            ))}
            <Col span={4}>
                <ComponentText
                    text={'Language'}
                    size={15}
                    font={fonts.SFProDisplayBold}
                    color={Colors.black_two}
                    align="left"
                />
                <Space height={16} />
                <Dropdown overlay={menu} placement="bottomLeft" className={'select-language'}>
                    <span>
                        <Row type={'flex'} justify={'start'}>
                            {' '}
                            English{' '}
                        </Row>
                    </span>
                </Dropdown>
                <Space height={30} />
                <ComponentText
                    text={'Follow us'}
                    size={15}
                    font={fonts.SFProDisplayBold}
                    color={Colors.black_two}
                    align="left"
                />
                <Space height={12} />
                <Row type={'flex'} align={'middle'} className={'follow'}>
                    <img alt="" src={Images.facebook} width={25} />
                    <Space width={12} />
                    <img alt="" src={Images.twitter} width={25} />
                    <Space width={12} />
                    <img alt="" src={Images.linkedin} width={25} />
                </Row>
            </Col>
        </Row>
    );
}

function MenuMinimum() {
    return (
        <Row type="flex" justify="start" align="middle">
            <Col span={13}>
                <Row type="flex" justify="start">
                    <Logo />
                </Row>
            </Col>
            <Col span={2}>
                <ComponentText text="Products" css="eina-semibold-14" align="left" />
            </Col>
            <Col span={2}>
                <a href="/pricing">
                    {' '}
                    <ComponentText text="Pricing" css="eina-semibold-14" align="left" />
                </a>
            </Col>
            <Col span={2}>
                <ComponentText text="About Us" css="eina-semibold-14" align="left" />
            </Col>
            <Col span={2}>
                <ComponentText text="Contact Us" css="eina-semibold-14" align="left" />
            </Col>

            {/*<Col span={1}>*/}
            {/*    <img alt="" src={images.facebook} width={30}/>*/}

            {/*</Col>*/}
            {/*<Col span={1}>*/}
            {/*    <img alt="" src={images.twitter} width={30}/>*/}

            {/*</Col>*/}
            {/*<Col span={1}>*/}
            {/*    <img alt="" src={images.linkedin} width={30}/>*/}

            {/*</Col>*/}
        </Row>
    );
}

const menu = (
    <Menu>
        <Menu.Item>English</Menu.Item>
    </Menu>
);
