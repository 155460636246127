import React from 'react';
import { Layout } from 'antd';

import './ContactUs.css';
import Banner from './Banner';
import { TopBar } from '../Shared/Menus/TopBar';
import { Footer } from '../Shared/Menus/Footer';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { EmailUs } from './EmailUs';

export class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        return (
            <div className="contact-us">
                <Layout style={{ backgroundColor: '#ffffff' }}>
                    <TopBar />
                    <Banner />
                    <EmailUs />
                    <ReadyToStartMobileBusiness />
                    <BottomMenu color="#fafafa" details={true} />
                    <Footer />
                </Layout>
            </div>
        );
    }
}
