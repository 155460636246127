import React from 'react';
import './Home.css';
import { Banner } from './Banner';

import { TopBar } from '../Shared/Menus/TopBar';
import { PurchaseFlyPoweredApps } from '../Shared/PurchaseFlyPoweredApps';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { ConnectApp } from './ConnectApp';
import { BuiltForDev } from './BuiltForDev';
import { FeaturesPurchaseFly } from '../Shared/FeaturesPurchaseFly';
import { Footer } from '../Shared/Menus/Footer';
import { TrustedCustomer } from "./TrustedCustomer";

class Home extends React.Component {
    render() {
        return (
            <div>
                <TopBar />
                <Banner />
                <PurchaseFlyPoweredApps />
                <ConnectApp />
                <FeaturesPurchaseFly title={'Feature-packed simplicity<br/>covers everything you need'} />
                <BuiltForDev />
                <TrustedCustomer/>
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}
export default Home;
