import React from 'react';
import Images from '../../../images/images';

export class LogoDocumentation extends React.Component {
    render() {
        return (
            <a href={'/documentation'}>
                <img src={Images.img_logo_documentation} height={32} alt={''} />
            </a>
        );
    }
}
