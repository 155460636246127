import React from 'react';
import { Col, Collapse, Icon, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import Space from '../Shared/ComponentUI/Spcae';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const { Panel } = Collapse;
export class FrequentlyAskedQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type="flex" className={'faq'} style={{ marginBottom: 50 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <ComponentText
                        text="Frequently Asked Questions"
                        align="center"
                        font={fonts.SFProDisplaySemiBold}
                        color={Colors.black}
                        size={35}
                    />
                    <Space height={80} />
                    <Row type="flex" gutter={60}>
                        <Col span={12}>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIconPosition="right"
                                expandIcon={({ isActive }) => (
                                    <Icon type={isActive ? 'minus' : 'plus'} rotate={isActive ? 0 : 0} />
                                )}
                            >
                                {FAQ.map((e, i) => {
                                    return (
                                        <Panel
                                            header={
                                                <ComponentText
                                                    text={e.title}
                                                    align="left"
                                                    font={fonts.SFProDisplayMedium}
                                                    color={Colors.black}
                                                    size={22}
                                                />
                                            }
                                            key={i}
                                            style={style}
                                        >
                                            <ComponentText
                                                text={e.description}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                color={Colors.cool_grey_three}
                                                size={22}
                                            />
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        </Col>
                        <Col span={12}>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIconPosition="right"
                                expandIcon={({ isActive }) => (
                                    <Icon type={isActive ? 'minus' : 'plus'} rotate={isActive ? 0 : 0} />
                                )}
                            >
                                {FAQ.map((e, i) => {
                                    return (
                                        <Panel
                                            header={
                                                <ComponentText
                                                    text={e.title}
                                                    align="left"
                                                    font={fonts.SFProDisplayMedium}
                                                    color={Colors.black}
                                                    size={22}
                                                />
                                            }
                                            key={i}
                                            style={style}
                                        >
                                            <ComponentText
                                                text={e.description}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                color={Colors.cool_grey_three}
                                                size={22}
                                            />
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        </Col>
                    </Row>

                    <Space height={80} />
                    <div className="description">
                        Have a different question about how Purchasefly works or the pricing plans
                        <br /> available? Try{' '}
                        <a href={'support'} style={{ color: 'var(--deep-sky-blue)' }}>
                            contact{' '}
                        </a>{' '}
                        with one of our specialists.
                    </div>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row className={'faq'} style={{ marginBottom: 50, paddingLeft: 16, paddingRight: 16 }}>
                <ComponentText
                    text="Frequently Asked Questions"
                    align="center"
                    font={fonts.SFProDisplaySemiBold}
                    color={Colors.black}
                    size={26}
                />
                <Space height={40} />
                <Row>
                    <Col span={24}>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIconPosition="right"
                            expandIcon={({ isActive }) => (
                                <Icon type={isActive ? 'minus' : 'plus'} rotate={isActive ? 0 : 0} />
                            )}
                        >
                            {FAQ.map((e, i) => {
                                return (
                                    <Panel
                                        header={
                                            <ComponentText
                                                text={e.title}
                                                align="left"
                                                font={fonts.SFProDisplayMedium}
                                                color={Colors.black}
                                                size={18}
                                            />
                                        }
                                        key={i}
                                        style={style}
                                    >
                                        <ComponentText
                                            text={e.description}
                                            align="left"
                                            font={fonts.SFProDisplayRegular}
                                            color={Colors.cool_grey_three}
                                            size={18}
                                        />
                                    </Panel>
                                );
                            })}
                        </Collapse>
                    </Col>
                </Row>

                <Space height={40} />
                <div className="description">
                    Have a different question about how Purchasefly works or the pricing plans available? Try{' '}
                    <a href={'support'} style={{ color: 'var(--deep-sky-blue)' }}>
                        contact{' '}
                    </a>{' '}
                    with one of our specialists.
                </div>
            </Row>
        );
    }
}
const style = {
    textAlign: 'left',
    borderRadius: 0,
    marginBottom: 40,
    paddingBottom: 28,
    borderBottom: '1px solid var(--pale-grey)',
    overflow: 'hidden',
};

const FAQ = [
    {
        title: 'How does the free 14-day trial work?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
    {
        title: 'What payment methods do you accept?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
    {
        title: 'What’s the difference between annual and monthly pricing?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
    {
        title: 'Do I need a lot of technical experience to use Purchasefly?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
    {
        title: 'Is Purchasefly GDPR compliant?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
    {
        title: 'What is MTR?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
    {
        title: 'Does Purchasefly pay me?',
        description:
            'You can sign up for Unbounce using Visa, Mastercard, American Express, or PayPal. GDPR compliance ensures we continue to deliver an industry-leading conversion platform for professional marketers in the European Union (EU) and marketers who conduct business with individuals based in the EU.',
    },
];
