import React from 'react';
import { Col, Divider, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import Space from '../Shared/ComponentUI/Spcae';
import { Colors } from '../../css/Colors';
import Images from '../../images/images';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Real-time subscription state<br/>of any user, anytime';

const content = [
    {
        icon: Images.yourApp,
        text: 'Your App',
        top: true,
        space: 20,
        width: 100,
    },
    {
        icon: Images.trialStarted,
        text: 'Trial Started',
        top: false,
        space: 12,
        width: 82,
    },
    {
        icon: Images.subscriptionStarted,
        text: 'Subscription<br/>started',
        top: false,
        space: 12,
        width: 82,
    },
    {
        icon: Images.subscriptionRenewed,
        text: 'Subscription<br/>renewed',
        top: false,
        space: 12,
        width: 82,
    },
    {
        icon: Images.subscriptionExpired,
        text: 'Subscription<br/>expired',
        top: false,
        space: 12,
        width: 82,
    },
    {
        icon: Images.logo,
        text: 'To You',
        top: false,
        space: 24,
        width: 320,
    },
];
export class RealTimeSubscriptionState extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type={'flex'} align={'middle'} justify={'space-between'} style={{ marginBottom: 80 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={50}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                    <Space height={84} />
                    <div className={'real-time-state-background'} />
                    <Col>
                        {prepareItem(content[0])}
                        <Space height={50} />
                        <Row
                            type={'flex'}
                            align={'top'}
                            justify={'space-between'}
                            style={{ paddingLeft: 64, paddingRight: 64 }}
                        >
                            {prepareItem(content[1])}
                            <img src={Images.arrowRight} width={32} style={{ paddingTop: 34 }} alt={''} />
                            {prepareItem(content[2])}
                            <img src={Images.arrowRight} width={32} style={{ paddingTop: 34 }} alt={''} />
                            {prepareItem(content[3])}
                            <img src={Images.arrowRight} width={32} style={{ paddingTop: 34 }} alt={''} />
                            {prepareItem(content[4])}
                        </Row>
                        <Space height={42} />
                        <img src={Images.arrowDown} width={16} alt={''} />
                        <Space height={42} />
                        {prepareItemLogo(content[5])}
                    </Col>
                    <Space height={150} />
                    <Divider />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row>
                <div style={{ paddingLeft: 50, paddingRight: 50 }}>
                    <ComponentText
                        text={title.replace('<br/>', ' ')}
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                </div>
                <Space height={45} />

                <div className={'real-time-state-background-mobile'} />
                {prepareItem(content[0])}
                <Space height={50} />
                <Row
                    type={'flex'}
                    align={'top'}
                    justify={'space-between'}
                    style={{ paddingLeft: 50, paddingRight: 50 }}
                >
                    {prepareItem(content[1])}
                    <img src={Images.arrowRight} width={32} style={{ paddingTop: 34 }} alt={''} />
                    {prepareItem(content[2])}
                </Row>

                <img src={Images.arrowTopDown} width={200} style={{ paddingTop: 16, paddingBottom: 16 }} alt={''} />

                <Row
                    type={'flex'}
                    align={'top'}
                    justify={'space-between'}
                    style={{ paddingLeft: 50, paddingRight: 50 }}
                >
                    {prepareItem(content[3])}
                    <img src={Images.arrowRight} width={32} style={{ paddingTop: 34 }} alt={''} />
                    {prepareItem(content[4])}
                </Row>
                <Space height={42} />
                <img src={Images.arrowDown} width={16} alt={''} />
                <Space height={42} />
                {prepareItemLogo(content[5])}
                <Space height={32} />
                <Divider />
                <Space height={60} />
            </Row>
        );
    }
}

function prepareItem(item) {
    return (
        <div>
            {item.top === true && (
                <ComponentText
                    text={item.text}
                    font={fonts.SFProDisplayMedium}
                    size={15}
                    color={Colors.cool_grey_three}
                    align={'center'}
                />
            )}
            {item.top === true && <Space height={item.space} />}
            <img src={item.icon} width={item.width} alt={''} />
            {item.top === false && <Space height={item.space} />}
            {item.top === false && (
                <ComponentText
                    text={item.text}
                    font={fonts.SFProDisplayMedium}
                    size={15}
                    color={Colors.cool_grey_three}
                    align={'center'}
                />
            )}
        </div>
    );
}

function prepareItemLogo(item) {
    return (
        <div>
            {item.top === true && (
                <ComponentText
                    text={item.text}
                    font={fonts.SFProDisplayMedium}
                    size={15}
                    color={Colors.cool_grey_three}
                    align={'center'}
                />
            )}
            {item.top === true && <Space height={item.space} />}
            <img src={item.icon} width={item.width} alt={''} className={'logo-shadow'} />
            {item.top === false && <Space height={item.space} />}
            {item.top === false && (
                <ComponentText
                    text={item.text}
                    font={fonts.SFProDisplayMedium}
                    size={15}
                    color={Colors.cool_grey_three}
                    align={'center'}
                />
            )}
        </div>
    );
}
