import React from 'react';
import { Row } from 'antd';
import Space from './ComponentUI/Spcae';
import { ComponentText } from './ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { ComponentIcon } from './ComponentUI/ComponentIcon';

export class FeatureButtonMobile extends React.Component {
    render() {
        let { image, title, description, to } = this.props;
        return (
            <a href={to}>
                {' '}
                <Row className={'feature-item-mobile'}>
                    <Row type={'flex'} align={'middle'} style={{ width: '335px' }}>
                        <img alt="" src={image} style={{ width: 36 }} />
                        <Space width={16} />
                        <Row type={'flex'} align={'middle'}>
                            <ComponentText
                                text={title}
                                align="center"
                                font={fonts.SFProDisplaySemiBold}
                                size={18}
                                color={Colors.dark}
                            />
                            <Space width={12} />
                            <ComponentIcon type={'arrow-right'} />
                        </Row>
                    </Row>

                    <Space height={16} />
                    <ComponentText
                        text={description}
                        align="start"
                        font={fonts.SFProDisplayRegular}
                        size={14}
                        color={Colors.slate_grey_two}
                    />
                </Row>{' '}
            </a>
        );
    }
}
