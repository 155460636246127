import React from 'react';
import './PlansProducts.css';
import { Banner } from './Banner';

import { TopBar } from '../Shared/Menus/TopBar';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { Footer } from '../Shared/Menus/Footer';
import { FeaturesPurchaseFly } from '../Shared/FeaturesPurchaseFly';
import Images from '../../images/images';
import { Features } from '../SubscriptionManagement/Features';
import { FlexiblePlanManagement } from './FlexiblePlanManagement';
import { ConfigureProducts } from './ConfigureProducts';

class PlansProducts extends React.Component {
    render() {
        return (
            <div>
                <TopBar />
                <Banner />
                <Features contents={features} />
                <FlexiblePlanManagement />
                <ConfigureProducts />
                <FeaturesPurchaseFly title={'There’s more for you'} index={1} />
                <ReadyToStartMobileBusiness />
                <BottomMenu color="#fafafa" details={true} />
                <Footer />
            </div>
        );
    }
}
export default PlansProducts;

const features = [
    {
        image: Images.realTimeSubscription,
        title: 'Analyze your subscribers',
        description:
            'Is a certain user non-paying, in trial or has active subscription? Forget about UserDefaults and Keychain. Apphud will keep user state up-to-date using Apple servers.',
        link: '',
        left: true,
        spans: [11, 13],
        space: 124,
    },
    {
        image: Images.validateSubscription,
        title: 'Validate in-app subscriptions',
        description:
            'OSecurely validate App Store receipts. Prevent fraud transactions and do not unlock premium features for cheaters.',
        link: '',
        left: false,
        spans: [11, 13],
        space: 80,
    },
];
