import React from 'react';
import { Button, Col, Row } from 'antd';
import { ComponentText } from './ComponentUI/ComponentText';
import Space from './ComponentUI/Spcae';
import { Colors } from '../../css/Colors';
import { fonts } from '../../fonts/fonts';
import { ComponentButton } from './ComponentUI/ComponentButton';
import Images from '../../images/images';
import { ComponentIcon } from './ComponentUI/ComponentIcon';
import { getDecreasingSpan, getIncreasingSpan } from './Col4';
import { links } from './Routes';

const title = 'Get your mobile<br/>business started';
const description = 'It’s easy to get started!';
export class ReadyToStartMobileBusiness extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    title = (size) => {
        return (
            <ComponentText
                text={title}
                align="left"
                font={fonts.SFProDisplayBold}
                color={Colors.black_four}
                size={size}
                lineHeight={'55px'}
            />
        );
    };
    description = (size) => {
        return (
            <ComponentText
                text={description}
                align="left"
                font={fonts.SFProDisplayRegular}
                color={Colors.slate_grey_two}
                size={size}
            />
        );
    };

    buttonSelectPlan = (css) => {
        return (
            <Button className={css} onClick={() => window.scrollTo({ top: 50, behavior: 'smooth' })}>
                <Row type="flex" align="middle" justify="center">
                    {' '}
                    Select a Plan <Space width={24} />
                    <ComponentIcon type="arrow-up" height={6} width={12} />
                </Row>
            </Button>
        );
    };

    buttonStartNow = (css) => {
        return (
            <a href={links.GetStarted}>
                <ComponentButton
                    text={'Get Started'}
                    css={'deep-sky-blue-and-white'}
                    iconRight={'arrow-right-white'}
                    height={50}
                    width={156}
                />
            </a>
        );
    };

    buttonViewDemo = (css) => {
        return (
            <a href={links.LiveDemo}>
                <ComponentButton
                    text={'Live Demo'}
                    css={'white-and-deep-sky-blue'}
                    iconRight={'arrow-right'}
                    height={50}
                    width={144}
                />
            </a>
        );
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        const { from } = this.props;

        return (
            <div>
                {' '}
                <Row
                    type="flex"
                    style={{ backgroundColor: Colors.pale_grey_five, paddingTop: 56, paddingBottom: 56 }}
                    align={'middle'}
                >
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 8, 1)}>
                        {this.title(50)}
                        <Space height={30} />
                        {this.description(22)}
                        <Space height={40} />
                        <Row type="flex" justify="start">
                            {from === 'pricing' ? this.buttonSelectPlan() : this.buttonStartNow()}
                            <Space width={16} />
                            {this.buttonViewDemo()}
                        </Row>
                    </Col>
                    <Col span={getIncreasingSpan(this.state.screenWidth, 8, 1)}>
                        <img src={Images.getStarted} alt={''} width={'100%'} />
                    </Col>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                </Row>
                <Space height={120} />
            </div>
        );
    }
    prepareMobileView() {
        return (
            <Row
                style={{ backgroundColor: Colors.pale_grey_five, paddingTop: 56, paddingBottom: 56, marginBottom: 80 }}
            >
                <ComponentText
                    text={title}
                    align="center"
                    font={fonts.SFProDisplaySemiBold}
                    color={Colors.black_four}
                    size={36}
                />
                <Space height="20" />
                <ComponentText
                    text={description}
                    align="center"
                    font={fonts.SFProDisplayRegular}
                    color={Colors.slate_grey_two}
                    size={16}
                />
                <Space height="40" />
                <Row type="flex" justify={'center'} align={'middle'}>
                    <a href={links.GetStarted}>
                        <ComponentButton
                            text={'Get Started'}
                            css={'deep-sky-blue-and-white-mobile'}
                            iconRight={'arrow-right-white'}
                            height={40}
                        />
                    </a>
                    <Space width="12" />
                    <a href={links.LiveDemo}>
                        <ComponentButton
                            text={'Live Demo'}
                            css={'white-and-deep-sky-blue-mobile'}
                            iconRight={'arrow-right'}
                            height={40}
                        />
                    </a>
                </Row>
                <Space height="50" />
                <img src={Images.getStarted} alt={''} width={'90%'} />
            </Row>
        );
    }
}
