import React from 'react';
import { Icon } from 'antd';

export class ComponentIcon extends React.Component {
    render() {
        const { type, antd } = this.props;
        const isAntd = getSVG(type) === 'antd';

        return antd || isAntd ? <Icon type={type} /> : <Icon className={type} component={getSVG(type)} />;
    }
}
function getSVG(type) {
    switch (type) {
        case 'arrow-right':
            return arrowRight;
        case 'arrow-right-white':
            return arrowRightWhite;
        case 'arrow-up':
            return arrowUp;
        case 'top':
            return top;
        case 'cancel':
            return cancel;
        default:
            return 'antd';
    }
}

const arrowRight = () => (
    <svg width="8" height="14" viewBox="0 0 8 14">
        <path
            fill="none"
            fillRule="evenodd"
            stroke="#007fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M0 0L6 6 0 12"
            transform="translate(1 1)"
        />
    </svg>
);

const arrowRightWhite = () => (
    <svg width="8" height="14" viewBox="0 0 8 14">
        <path
            fill="none"
            fillRule="evenodd"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M0 0L6 6 0 12"
            transform="translate(1 1)"
        />
    </svg>
);

const arrowUp = () => (
    <svg viewBox="0 0 14 8">
        <path
            fill="none"
            fillRule="evenodd"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 7l6-6 6 6"
        />
    </svg>
);

const top = () => (
    <svg width="10" height="12" viewBox="0 0 10 12">
        <path
            fill="#566973"
            fillRule="nonzero"
            d="M4.24 2.648L1.273 5.711a.734.734 0 0 1-1.053-.01.794.794 0 0 1-.01-1.086L4.46.228A.737.737 0 0 1 4.99 0c.2 0 .391.082.532.228L9.77 4.615a.792.792 0 0 1 .205.754.764.764 0 0 1-.537.554.736.736 0 0 1-.73-.212L5.742 2.648v8.577A.764.764 0 0 1 4.99 12a.764.764 0 0 1-.751-.775V2.648z"
        />
    </svg>
);

const cancel = () => (
    <svg viewBox="0 0 17 17">
        <g fill="none" fillRule="evenodd" stroke="#A3AFB8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M16 1L1 16M1 1l15 15" />
        </g>
    </svg>
);
