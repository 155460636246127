import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export class ComponentText extends React.Component {
    render() {
        let { text, font, size, color, align, lineHeight } = this.props;
        if (!lineHeight) {
            switch (size) {
                case 14:
                    lineHeight = '20px';
                    break;
                case 16:
                    lineHeight = '22px';
                    break;
                case 20:
                    lineHeight = '26px';
                    break;
                case 22:
                    lineHeight = '32px';
                    break;
                case 50:
                    lineHeight = '55px';
                    break;
                case 60:
                    lineHeight = '65px';
                    break;
                default:
                    lineHeight = 1;
            }
        }
        const style = {
            color: color,
            fontSize: `${size}px`,
            fontFamily: font,
            lineHeight: lineHeight,
            transitionDuration: '0.5s, 0s',
        };

        return (
            <div style={{ textAlign: align }}>
                {' '}
                {text.split('<br/>').map((item, i) => (
                    <Text key={i} style={style}>
                        {item} <br />{' '}
                    </Text>
                ))}{' '}
            </div>
        );
    }
}
