import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Space from '../Shared/ComponentUI/Spcae';
import { fonts } from '../../fonts/fonts';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import Images from '../../images/images';
import { getDecreasingSpan, getIncreasingSpan, getSpan2 } from '../Shared/Col4';
import ReactPlayer from 'react-player';
import { links } from '../Shared/Routes';

const title = 'Make your subscription business fly';
const description =
    'Spread your wings with our enterprise-class mobile in-app subscription management API and solutions. ';
export class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView = () => {
        return (
            <div>
                {' '}
                <Space height={80} />{' '}
                <Row type="flex" align={'middle'}>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 8, 1)} style={{ paddingRight: 64 }}>
                        <ComponentText
                            text={title}
                            font={fonts.SFProDisplayBold}
                            size={60}
                            color={'var(--black-four)'}
                            align={'left'}
                        />
                        <Space height={30} />
                        <ComponentText
                            text={description}
                            lineHeight={'32px'}
                            font={fonts.SFProDisplayRegular}
                            size={22}
                            color={'var(--slate-grey-two)'}
                            align={'left'}
                        />
                        <Space height={56} />
                        <Row type="flex" justify="start">
                            <a href={links.GetStarted}>
                                <ComponentButton
                                    text={'Get Started'}
                                    css={'deep-sky-blue-and-white'}
                                    iconRight={'arrow-right-white'}
                                    height={50}
                                    width={156}
                                />
                            </a>
                            <Space width={16} />
                            <a href={links.LiveDemo}>
                                <ComponentButton
                                    text={'Live Demo'}
                                    css={'white-and-deep-sky-blue'}
                                    iconRight={'arrow-right'}
                                    height={50}
                                    width={156}
                                />
                            </a>
                        </Row>
                    </Col>
                    <Col span={getIncreasingSpan(this.state.screenWidth, 10, 1)}>
                        {/* <img src={Images.hero_animation} width={"100%"} alt={""}/> */}
                        <ReactPlayer
                            muted={true}
                            playing={true}
                            loop={true}
                            controls={false}
                            url={Images.homeVideoOptimized}
                            width="100%"
                            height={null}
                        />
                    </Col>
                    <Col span={getSpan2(this.state.screenWidth)} />
                </Row>
                <Space height={150} />
            </div>
        );
    };

    prepareMobileView = () => {
        return (
            <Row style={{ padding: 20, marginTop: 40, marginBottom: 90 }}>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                </div>
                <Space height={22} />
                <ComponentText
                    text={description}
                    font={fonts.SFProDisplayRegular}
                    size={16}
                    color={'var(--slate-grey-two)'}
                    align={'center'}
                />
                <Space height={40} />
                <ReactPlayer
                    muted={true}
                    playing={true}
                    loop={true}
                    controls={false}
                    url={Images.homeVideoOptimized}
                    width="100%"
                    height={null}
                />
                <Row type="flex" justify="center">
                    <a href={links.GetStarted}>
                        <ComponentButton
                            text={'Get Started'}
                            css={'deep-sky-blue-and-white-mobile'}
                            iconRight={'arrow-right-white'}
                            height={40}
                        />
                    </a>
                    <Space width={16} />
                    <a href={links.LiveDemo}>
                        <ComponentButton
                            text={'Live Demo'}
                            css={'white-and-deep-sky-blue-mobile'}
                            iconRight={'arrow-right'}
                            height={40}
                        />
                    </a>
                </Row>
            </Row>
        );
    };
}
