import React from 'react';

class Space extends React.Component {
    render() {
        const { height, width } = this.props;
        const style = { height: height + 'px', width: width + 'px' };
        return <div style={style} />;
    }
}
export default Space;
