import React from 'react';
import { Col, Divider, Row } from 'antd';
import { ComponentText } from './ComponentUI/ComponentText';

import Space from './ComponentUI/Spcae';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import { getDecreasingSpan, getIncreasingSpan } from './Col4';

const title = 'Over 3 billion recurring revenue';
const subtitle = 'Managed annually for subscription businesses such as';

export class PurchaseFlyPoweredApps extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    prepareApps = (width, css) => {
        return (
            <Row className={css}>
                {apps.map((e, i) => {
                    return <img alt="" key={i} src={e} width={width} />;
                })}
            </Row>
        );
    };
    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <div>
                <Row type="flex" align={'middle'}>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 5, 1)}>
                        <ComponentText
                            text={title}
                            align="left"
                            font={fonts.SFProDisplayBold}
                            size={40}
                            color={'var(--black-four)'}
                        />
                        <Space height={16} />
                        <ComponentText
                            text={subtitle}
                            align="left"
                            font={fonts.SFProDisplayRegular}
                            size={22}
                            color={'var(--slate-grey-two)'}
                            lineHeight={'32px'}
                        />
                    </Col>

                    <Col span={getIncreasingSpan(this.state.screenWidth, 11, 1)}>
                        <Divider style={{ marginBottom: 10 }} />
                        {
                            <Row type="flex" style={{ marginLeft: 40 }}>
                                {this.prepareApps('100px', 'powered-apps')}
                            </Row>
                        }
                        <Divider style={{ marginTop: 10 }} />
                    </Col>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                </Row>{' '}
                <Space height={150} />
            </div>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ marginBottom: 60 }}>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title}
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={'var(--black-four)'}
                    />
                </div>
                <Space height={22} />
                <ComponentText
                    text={subtitle}
                    align="center"
                    font={fonts.SFProDisplayRegular}
                    size={22}
                    color={'var(--slate-grey-two)'}
                />

                <Row style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 36, paddingBottom: 0 }}>
                    {this.prepareApps('100px', 'powered-apps-mobile')}
                </Row>
            </Row>
        );
    }
}

const apps = [Images.phoneline, Images.textr, Images.prankdial, Images.chatflix, Images.prankpad];
