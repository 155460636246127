import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import Space from '../Shared/ComponentUI/Spcae';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

export class EmailUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type="flex" style={{ textAlign: 'left', marginTop: 110, marginBottom: 120 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Row type={'flex'} gutter={40} justify={'space-between'}>
                        {contents.map((e, i) => (
                            <Col key={i} span={12}>
                                <div className={'email-us'}>
                                    <img alt="" src={e.icon} width={32} />
                                    <Space height={10} />
                                    <ComponentText
                                        text={e.title}
                                        font={fonts.SFProDisplaySemiBold}
                                        size={30}
                                        color={Colors.black}
                                    />
                                    <Space height={10} />
                                    <ComponentText
                                        text={e.description}
                                        font={fonts.SFProDisplayRegular}
                                        size={22}
                                        color={Colors.slate_grey}
                                    />
                                    <Space height={24} />
                                    <ComponentText
                                        text={e.extra}
                                        font={fonts.SFProDisplayRegular}
                                        size={22}
                                        color={Colors.water_blue}
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ marginBottom: 40 }}>
                {contents.map((e, i) => (
                    <div key={i} className={'email-us'} style={{ margin: 20 }}>
                        <img alt="" src={e.icon} width={32} />
                        <Space height={10} />
                        <ComponentText
                            text={e.title}
                            font={fonts.SFProDisplaySemiBold}
                            size={30}
                            color={Colors.black}
                        />
                        <Space height={10} />
                        <ComponentText
                            text={e.description}
                            font={fonts.SFProDisplayRegular}
                            size={22}
                            color={Colors.slate_grey}
                        />
                        <Space height={24} />
                        <ComponentText
                            text={e.extra}
                            font={fonts.SFProDisplayRegular}
                            size={22}
                            color={Colors.water_blue}
                        />
                    </div>
                ))}
            </Row>
        );
    }
}

const contents = [
    {
        icon: Images.emailus,
        title: 'Email us',
        description: 'Email us for general queries, including marketing and partnership opportunities.',
        extra: 'info@pruchasefly.com',
    },
    {
        icon: Images.support,
        title: 'Support',
        description: 'Check out helpful resources, FAQs and developer tools.',
        extra: 'Developer Resources',
    },
];
