import React from 'react';
import { Col, Divider, Layout, Row, Spin } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import Space from '../Shared/ComponentUI/Spcae';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';
import { links } from '../Shared/Routes';
import BlocBuilder from 'bloc-builder-react';
import PricingBloc from './PricingBloc';
import { planTypes, productTypes } from './PricingPlans';

const { Content } = Layout;

export class ComparePlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    getSelectedPlan = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('plan');
        return token ? token : planTypes.Monthly;
    };

    getSelectedProduct = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('product');
        return token ? token : productTypes.Startup;
    };

    getPrice = (price, product) => {
        if (product.subscription_plans.length === 1) {
            return 'Free';
        } else {
            const selectedPlan = this.getSelectedPlan();
            const plan = product.subscription_plans.find((e) => e.type === selectedPlan);
            return `$${plan.price}`;
        }
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    getMTR = (product) => {
        if (product) {
            const mtr = product.features.find((e) => e.name === 'MTR-Limit');
            return `$${mtr.value}`;
        } else return '';
    };

    prepareView() {
        return (
            <Content style={{ marginTop: 128, marginBottom: 96 }}>
                <Row type="flex" justify="center">
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                        <ComponentText
                            text="Choose the plan that’s right for you"
                            align="center"
                            font={fonts.SFProDisplaySemiBold}
                            size={35}
                            color={Colors.black}
                        />
                        <Space height={40} />
                        <Divider />
                        <Space height={60} />
                        <Row type={'flex'} gutter={38}>
                            {headerItems.map((e, i) => {
                                return i === 0 ? (
                                    <Col key={i} span={9} />
                                ) : (
                                    <Col key={i} span={5}>
                                        <ComponentText
                                            text={e.title}
                                            align="center"
                                            font={fonts.SFProDisplaySemiBold}
                                            size={22}
                                            color={Colors.black}
                                        />
                                        <Space height={16} />
                                        <BlocBuilder
                                            subject={PricingBloc.instance.getProductSubject()}
                                            builder={(snapshot) => {
                                                return snapshot.data ? (
                                                    <ComponentText
                                                        text={this.getPrice(
                                                            e.price,
                                                            snapshot.data.find((p) => p.name === e.title)
                                                        )}
                                                        align="center"
                                                        font={fonts.SFProDisplayMedium}
                                                        size={16}
                                                        color={Colors.slate_grey}
                                                    />
                                                ) : (
                                                    <Spin />
                                                );
                                            }}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                        <Space height={30} />
                        {featureItems.map((e, i) => {
                            return (
                                <Row key={i} type={'flex'} gutter={38}>
                                    <Col span={9}>
                                        <Row
                                            type={'flex'}
                                            align={'middle'}
                                            justify={'start'}
                                            className={'compare-column-title'}
                                        >
                                            <ComponentText
                                                text={e.title}
                                                align="center"
                                                font={fonts.SFProDisplayRegular}
                                                size={20}
                                                color={Colors.black}
                                            />
                                        </Row>
                                    </Col>
                                    {this.PrepareColumnItem(e.basic, i, 'Build')}
                                    {this.PrepareColumnItem(e.startup, i, 'Analyze')}
                                    {this.PrepareColumnItem(e.business, i, 'Business')}
                                </Row>
                            );
                        })}
                        <Space height={30} />
                        <Row type={'flex'} gutter={38}>
                            {footerItems.map((e, i) => {
                                return i === 0 ? (
                                    <Col key={i} span={9}>
                                        <Row
                                            type={'flex'}
                                            align={'middle'}
                                            justify={'start'}
                                            className={'compare-column-footer-title'}
                                        >
                                            {' '}
                                            <ComponentText
                                                text={e.title}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                size={22}
                                                color={Colors.deep_sky_blue}
                                            />
                                        </Row>
                                    </Col>
                                ) : (
                                    <Col key={i} span={5}>
                                        <a href={e.to}>
                                            {' '}
                                            <ComponentButton text={e.button} css={e.style} />
                                        </a>
                                        <Space height={16} />
                                        <ComponentText
                                            text={e.title}
                                            align="center"
                                            font={fonts.SFProDisplayRegular}
                                            size={22}
                                            color={Colors.slate_grey}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                        <Space height={80} />
                        <Divider />
                    </Col>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                </Row>
            </Content>
        );
    }
    prepareMobileView() {
        return (
            <Content style={{ marginTop: 128, marginBottom: 96 }} className="compare-plan-mobile">
                <Row type="flex" justify="center" style={{ paddingLeft: 16, paddingRight: 12 }}>
                    <Col span={24}>
                        <ComponentText
                            text="Choose the plan that’s right for you"
                            align="center"
                            font={fonts.SFProDisplaySemiBold}
                            size={24}
                            color={Colors.black}
                        />

                        <Space height={60} />
                        <Row type={'flex'} gutter={38}>
                            {headerItems.map((e, i) => {
                                return i === 0 ? (
                                    <Col key={i} span={9} />
                                ) : (
                                    <Col key={i} span={5}>
                                        <ComponentText
                                            text={e.title}
                                            align="center"
                                            font={fonts.SFProDisplaySemiBold}
                                            size={10}
                                            color={Colors.black}
                                        />
                                        <Space height={16} />
                                        <BlocBuilder
                                            subject={PricingBloc.instance.getProductSubject()}
                                            builder={(snapshot) => {
                                                return snapshot?.data ? (
                                                    <ComponentText
                                                        text={this.getPrice(
                                                            e.price,
                                                            snapshot.data.find((p) => p.name === e.title)
                                                        )}
                                                        align="center"
                                                        font={fonts.SFProDisplayMedium}
                                                        size={14}
                                                        color={Colors.slate_grey}
                                                    />
                                                ) : (
                                                    <Spin />
                                                );
                                            }}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                        <Space height={30} />
                        {featureItems.map((e, i) => {
                            return (
                                <Row key={i} type={'flex'} gutter={8}>
                                    <Col span={9}>
                                        <Row
                                            type={'flex'}
                                            align={'middle'}
                                            justify={'start'}
                                            className={'compare-column-title'}
                                        >
                                            <ComponentText
                                                text={e.title}
                                                align="left"
                                                font={fonts.SFProDisplayRegular}
                                                size={14}
                                                color={Colors.black}
                                            />
                                        </Row>
                                    </Col>
                                    {this.PrepareColumnItem(e.basic, i, 'Build')}
                                    {this.PrepareColumnItem(e.startup, i, 'Analyze')}
                                    {this.PrepareColumnItem(e.business, i, 'Business')}
                                </Row>
                            );
                        })}
                        <Space height={30} />
                        <Divider />
                    </Col>
                </Row>
            </Content>
        );
    }

    PrepareColumnItem(item, index, title) {
        return (
            <Col span={5}>
                {item === '' ? (
                    <Row
                        type={'flex'}
                        align={'middle'}
                        justify={'center'}
                        className={index === 10 ? 'compare-column-item-last' : 'compare-column-item'}
                    />
                ) : item === true ? (
                    <Row
                        type={'flex'}
                        align={'middle'}
                        justify={'center'}
                        className={index === 10 ? 'compare-column-item-last' : 'compare-column-item'}
                    >
                        <img src={Images.check} width={24} alt={''} />
                    </Row>
                ) : (
                    <Row
                        type={'flex'}
                        align={'middle'}
                        justify={'center'}
                        className={index === 0 ? 'compare-column-item-first' : 'compare-column-item'}
                    >
                        <BlocBuilder
                            subject={PricingBloc.instance.getProductSubject()}
                            builder={(snapshot) => {
                                return (
                                    <ComponentText
                                        text={
                                            index === 0
                                                ? this.getMTR(snapshot.data?.find((e) => e.name === title))
                                                : item
                                        }
                                        align="center"
                                        font={fonts.SFProDisplayMedium}
                                        size={16}
                                        color={Colors.slate_grey}
                                    />
                                );
                            }}
                        />
                    </Row>
                )}
            </Col>
        );
    }
}

const headerItems = [
    {
        title: '',
        price: '',
    },
    {
        title: 'Build',
        price: 'Free',
    },
    {
        title: 'Analyze',
        price: '$79',
    },
    {
        title: 'Business',
        price: '$290',
    },
];
const featureItems = [
    { title: 'Monthly Tracked Revenue (MTR)', basic: '$10,000', startup: '$20,000', business: '$100,000', key: 1 },
    { title: 'Basic Metrics', basic: true, startup: true, business: true, key: 2 },
    { title: 'Status Tracking', basic: true, startup: true, business: true, key: 3 },
    { title: 'Advanced Charts', basic: '', startup: true, business: true, key: 4 },
    { title: 'Apple Search Ads Dashboard', basic: '', startup: true, business: true, key: 5 },
    { title: 'Server-to-Server Webhooks', basic: '', startup: true, business: true, key: 6 },
    { title: 'Basic Integrations', basic: '', startup: true, business: true, key: 7 },
    { title: 'Collaborators', basic: '', startup: true, business: true, key: 8 },
    { title: 'Advanced Integrations', basic: '', startup: '', business: true, key: 9 },
    { title: 'Attribution Source Tracking', basic: '', startup: '', business: true, key: 10 },
    { title: 'ETL Export', basic: '', startup: '', business: true, key: 11 },
];
const footerItems = [
    {
        title: 'Getting started is easy',
        button: '',
    },
    {
        title: 'Build',
        button: 'Create account',
        style: 'button-create-account',
        to: links.CreateAccount,
    },
    {
        title: 'Analyze',
        button: 'Try free for 14 days',
        style: 'button-try-free',
        to: links.FreeTrial,
    },
    {
        title: 'Business',
        button: 'Try free for 14 days',
        style: 'button-try-free',
        to: links.FreeTrial,
    },
];
