import React from 'react';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Col, Divider, Row } from 'antd';
import Images from '../../images/images';
import Space from '../Shared/ComponentUI/Spcae';
import { Colors } from '../../css/Colors';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const title = 'Comprehensive timeline of<br/>your subscribers’ activity';
const contents = [
    {
        icon: Images.customerTrialStarted,
        title: 'Trial Started',
        date: 'Aug 2, 2019 10:50 PM',
        cost: '$0.00',
    },
    {
        col: 4,
        icon: Images.arrowDot,
    },
    {
        icon: Images.customerMonthly,
        title: 'Monthly Subscription started',
        date: 'Nov 13, 2019 7:37 PM',
        cost: '$8.99',
    },
    {
        col: 4,
        icon: Images.arrowDot,
    },
    {
        icon: Images.customerYearly,
        title: 'Yearly Subscription renewed',
        date: 'Dec 13, 2019 7:37 PM',
        cost: '$99.99',
    },
];
export class ComprehensiveTimeLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type={'flex'} align={'middle'} justify={'space-between'} style={{ marginTop: 84, marginBottom: 84 }}>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <ComponentText
                        text={title}
                        font={fonts.SFProDisplayBold}
                        size={50}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                    <Space height={84} />
                    <Row type={'flex'} align={'top'} justify={'center'}>
                        {contents.map((e, i) => (
                            <Col key={i} span={e.col ? 2 : 5}>
                                {e.col ? (
                                    <img src={e.icon} width={100} alt={''} style={{ width: '100%', marginTop: 90 }} />
                                ) : (
                                    <div>
                                        <div>
                                            <span className={'timeline-date'}> {e.date} </span>
                                        </div>
                                        <Space height={24} />
                                        <img src={e.icon} width={100} alt={''} />
                                        <Space height={12} />
                                        <ComponentText
                                            text={e.title}
                                            font={fonts.SFProDisplayMedium}
                                            size={15}
                                            color={Colors.cool_grey_three}
                                            align={'center'}
                                        />
                                        <Space height={32} />
                                        <ComponentText
                                            text={e.cost}
                                            font={fonts.SFProDisplayMedium}
                                            size={13}
                                            color={'var(--greyish-brown)'}
                                            align={'center'}
                                        />
                                    </div>
                                )}
                            </Col>
                        ))}
                    </Row>
                    <Space height={150} />
                    <Divider />
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                    <ComponentText
                        text={title.replace('<br/>', ' ')}
                        font={fonts.SFProDisplayBold}
                        size={32}
                        color={'var(--black-four)'}
                        align={'center'}
                    />
                </div>
                <Space height={60} />
                {contents.map((e, i) => (
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <div>
                            <span className={'timeline-date'}> {e.date} </span>
                        </div>
                        <Space height={24} />
                        <img src={e.icon} width={100} alt={''} />
                        <Space height={12} />
                        <ComponentText
                            text={e.title}
                            font={fonts.SFProDisplayMedium}
                            size={15}
                            color={Colors.cool_grey_three}
                            align={'center'}
                        />
                        <Space height={12} />
                        <ComponentText
                            text={e.cost}
                            font={fonts.SFProDisplayMedium}
                            size={13}
                            color={'var(--greyish-brown)'}
                            align={'center'}
                        />
                        <Space height={30} />
                        <img src={Images.arrowDotMobile} width={20} alt={''} />
                        <Space height={30} />
                    </div>
                ))}
                <Space height={60} />
                <Divider />
            </Row>
        );
    }
}
