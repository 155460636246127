import React from 'react';
import { Col, Row, Slider, Spin } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import Space from '../Shared/ComponentUI/Spcae';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';
import PricingBloc from './PricingBloc';
import BlocBuilder from 'bloc-builder-react';

function PlanStart({ title, cost, discount, to }) {
    return (
        <div>
            <Row type={'flex'} align={'middle'}>
                <ComponentText
                    text={title}
                    align="left"
                    font={fonts.SFProDisplayRegular}
                    size={22}
                    color={Colors.slate_grey}
                />
                <Space width={16} />
                {discount.length > 0 && discount !== 'Save 0%' && (
                    <ComponentText
                        text={discount}
                        align="left"
                        font={fonts.SFProDisplayRegular}
                        size={14}
                        color={Colors.deep_sky_blue}
                    />
                )}
            </Row>
            <Space height={12} />
            <Row type={'flex'} align={'middle'}>
                <ComponentText
                    text={cost}
                    align="left"
                    font={fonts.SFProDisplayMedium}
                    size={22}
                    color={Colors.black}
                />
                <Space width={16} />
                <a href={to}>
                    <ComponentButton text={'Start Trial'} height={42} width={110} css={'deep-sky-blue-and-white'} />
                </a>
            </Row>
        </div>
    );
}

function PlanStartMobile({ title, cost, discount, to }) {
    return (
        <div>
            <Row type={'flex'} align={'middle'}>
                <ComponentText
                    text={title}
                    align="left"
                    font={fonts.SFProDisplayRegular}
                    size={22}
                    color={Colors.slate_grey}
                />
                <Space width={16} />
                {discount.length > 0 && discount !== 'Save 0%' && (
                    <ComponentText
                        text={discount}
                        align="left"
                        font={fonts.SFProDisplayRegular}
                        size={14}
                        color={Colors.deep_sky_blue}
                    />
                )}
            </Row>
            <Space height={12} />
            <Row type={'flex'} align={'middle'} justify={'space-between'}>
                <ComponentText
                    text={cost}
                    align="left"
                    font={fonts.SFProDisplayMedium}
                    size={22}
                    color={Colors.black}
                />
                <Space width={16} />
                <a href={to}>
                    <ComponentButton
                        text={'Start Trial'}
                        height={42}
                        width={110}
                        css={'deep-sky-blue-and-white-mobile'}
                    />
                </a>
            </Row>
        </div>
    );
}

export class EstimateCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: 0, screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    onChange = (value) => {
        let x = 0;
        mtrRange.forEach((e) => {
            if (value >= e.start && value < e.end) {
                x = Math.round((e.value / e.end) * value);
            } else if (value === e.end) {
                x = e.value;
            }
        });
        this.setState({
            value: x,
        });
        PricingBloc.instance.setSelectedMTR(x);
    };
    prepareView() {
        return (
            <Row>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Row className={'estimate-cost'}>
                        <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                            <div className={'estimate-cost-mtr'}>
                                <ComponentText
                                    text={'Estimate your cost'}
                                    align="left"
                                    font={fonts.SFProDisplaySemiBold}
                                    size={30}
                                    color={Colors.black}
                                />
                                <Space height={16} />
                                <Row type={'flex'} justify={'space-between'}>
                                    <ComponentText
                                        text={'What’s your current MTR?'}
                                        align="left"
                                        font={fonts.SFProDisplayRegular}
                                        size={22}
                                        color={Colors.slate_grey}
                                    />
                                    <BlocBuilder
                                        subject={PricingBloc.instance.getMTRSubject()}
                                        builder={(snapshot) => {
                                            return (
                                                <ComponentText
                                                    text={snapshot.data + 'K MTR'}
                                                    align="left"
                                                    font={fonts.SFProDisplayMedium}
                                                    size={22}
                                                    color={Colors.black}
                                                />
                                            );
                                        }}
                                    />
                                </Row>
                                <Space height={60} />
                                <Slider
                                    marks={marks}
                                    step={5}
                                    defaultValue={0}
                                    tooltipVisible={false}
                                    onChange={this.onChange}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <BlocBuilder
                                subject={PricingBloc.instance.getBilling()}
                                builder={(snapshot) => {
                                    return snapshot.data ? (
                                        <div className={'estimate-cost-start'}>
                                            <PlanStart
                                                title={'Monthly Billing'}
                                                cost={`$${snapshot.data.monthly} /mo`}
                                                discount={''}
                                            />
                                            <Space height={40} />
                                            <PlanStart
                                                title={'Annual Billing'}
                                                cost={`$${snapshot.data.annualy} /mo`}
                                                discount={`Save ${snapshot.data.savings}%`}
                                            />
                                        </div>
                                    ) : (
                                        <Spin />
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row style={{ paddingRight: 16, paddingLeft: 16 }}>
                <div>
                    <ComponentText
                        text={'Estimate your cost'}
                        align="left"
                        font={fonts.SFProDisplaySemiBold}
                        size={28}
                        color={Colors.black}
                    />
                    <Space height={16} />
                    <ComponentText
                        text={'What’s your current MTR?'}
                        align="left"
                        font={fonts.SFProDisplayRegular}
                        size={18}
                        color={Colors.slate_grey}
                    />
                    <Space height={24} />
                    <BlocBuilder
                        subject={PricingBloc.instance.getMTRSubject()}
                        builder={(snapshot) => {
                            return (
                                <ComponentText
                                    text={snapshot.data + 'K MTR'}
                                    align="left"
                                    font={fonts.SFProDisplayMedium}
                                    size={20}
                                    color={Colors.black}
                                />
                            );
                        }}
                    />
                    <Space height={24} />
                    <Slider step={5} defaultValue={0} tooltipVisible={false} onChange={this.onChange} />
                </div>
                <Space height={40} />
                <BlocBuilder
                    subject={PricingBloc.instance.getBilling()}
                    builder={(snapshot) => {
                        return snapshot.data ? (
                            <div>
                                <PlanStartMobile
                                    title={'Monthly Billing'}
                                    cost={`$${snapshot.data.monthly} /mo`}
                                    discount={''}
                                />
                                <Space height={40} />
                                <PlanStartMobile
                                    title={'Annual Billing'}
                                    cost={`$${snapshot.data.annualy} /mo`}
                                    discount={`Save ${snapshot.data.savings}%`}
                                />
                            </div>
                        ) : (
                            <Spin />
                        );
                    }}
                />
            </Row>
        );
    }
}
const marks = {
    0: '0',
    15: '30k',
    30: '60k',
    45: '90k',
    60: '120k',
    75: '150k',
    90: '180k',
    100: '200k+',
};
const mtrRange = [
    {
        start: 0,
        end: 15,
        value: 30,
    },
    {
        start: 16,
        end: 30,
        value: 60,
    },
    {
        start: 31,
        end: 45,
        value: 90,
    },
    {
        start: 46,
        end: 60,
        value: 120,
    },
    {
        start: 61,
        end: 75,
        value: 150,
    },
    {
        start: 75,
        end: 90,
        value: 180,
    },
    {
        start: 90,
        end: 100,
        value: 200,
    },
];
