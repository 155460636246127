export const paths = {
    Home: '/home',
    SubscriptionManagement: '/subscription-management',
    ChartsMetrics: '/charts-metrics',
    PlansProduct: '/plans-products',
    CustomerProfile: '/customer-profile',
    PricingPlans: '/pricing-plans',
    ContactUs: '/contact-us',
    AboutUs: '/about-us',
    Documentation: '/documentation',
    Blog: '/blog',
    Login: '/login',
    SignUp: '/register',
    LiveDemo: '/live-demo',
    TermsPolicy: '/terms-policy',
};

export const links = {
    GetStarted: 'get-started',
    LiveDemo: 'live-demo',
    SDK: 'sdk',
    Configure: 'configure',
    Analyze: 'analyze',
    Subscription: 'subscription',
    Webhooks: 'webhooks',
    PlanAnnual: '?plan=annual',
    PlanMonthly: '?plan=monthly',
    CreateAccount: 'create-account',
    FreeTrial: 'free-trial',
    Support: 'support',
    Terms: 'terms-policy?menu=0',
    PrivacyPolicy: 'terms-policy?menu=1',
};
