import React from 'react';
import { ComponentText } from '../ComponentUI/ComponentText';
import { Col, Row } from 'antd';
import { fonts } from '../../../fonts/fonts';
import { Colors } from '../../../css/Colors';
import { Logo } from './Logo';
import Space from '../ComponentUI/Spcae';
import { ComponentIcon } from '../ComponentUI/ComponentIcon';
import './Menu.css';
import { getDecreasingSpan, getIncreasingSpan } from '../Col4';
import { paths, links } from '../Routes';

export class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }

    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <Row type={'flex'}>
                {/*<Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)}/>*/}
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)}>
                    <Row type={'flex'} justify={'space-between'}>
                        <div style={{ width: 50 }} />
                        <Logo />
                        <div className={'button-top'} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <Row type={'flex'} align={'middle'}>
                                <ComponentText
                                    text="Top"
                                    font={fonts.SFProDisplayMedium}
                                    size={14}
                                    color={Colors.slate}
                                    align="left"
                                />
                                <Space width={12} />
                                <ComponentIcon type={'top'} />
                            </Row>
                        </div>
                    </Row>
                    <Space height={20} />

                    <Row type={'flex'} justify={'center'} align={'middle'} className={'footer-link'}>
                        <a href={links.Terms}>
                            <ComponentText
                                text="Terms and conditions"
                                font={fonts.SFProDisplayMedium}
                                size={12}
                                color={Colors.battleship_grey}
                                align="left"
                            />
                        </a>
                        <Space width={12} />
                        <ComponentText
                            text="•"
                            font={fonts.SFProDisplayMedium}
                            size={12}
                            color={Colors.battleship_grey}
                            align="left"
                        />
                        <Space width={12} />
                        <a href={links.PrivacyPolicy}>
                            <ComponentText
                                text="Privacy Policy"
                                font={fonts.SFProDisplayMedium}
                                size={12}
                                color={Colors.battleship_grey}
                                align="left"
                            />
                        </a>
                        <Space width={12} />
                        <ComponentText
                            text="•"
                            font={fonts.SFProDisplayMedium}
                            size={12}
                            color={Colors.battleship_grey}
                            align="left"
                        />
                        <Space width={12} />
                        <a href={paths.Login}>
                            <ComponentText
                                text="Login"
                                font={fonts.SFProDisplayMedium}
                                size={12}
                                color={Colors.battleship_grey}
                                align="left"
                            />
                        </a>
                    </Row>
                    <Space height={16} />
                    <Row type={'flex'} justify={'center'}>
                        <ComponentText
                            text="© Copyright 2022 purchasefly. All Rights Reserved."
                            font={fonts.SFProDisplayMedium}
                            size={12}
                            color={Colors.cool_grey_three}
                            align="left"
                        />
                    </Row>
                    <Space height={20} />
                </Col>
                {/*<Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)}/>*/}
                <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
            </Row>
        );
    }
    prepareMobileView() {
        return (
            <Row>
                <Row type={'flex'} align={'middle'} justify={'center'}>
                    <ComponentText
                        text="Go to top"
                        font={fonts.SFProDisplayMedium}
                        size={14}
                        color={Colors.slate}
                        align="left"
                    />
                    <Space width={12} />
                    <ComponentIcon type={'top'} />
                </Row>
                <Space height={30} />

                <Row type={'flex'} justify={'center'} align={'middle'} className={'footer-link'}>
                    <a href={paths.TermsPolicy}>
                        <ComponentText
                            text="Terms and conditions"
                            font={fonts.SFProDisplayMedium}
                            size={12}
                            color={Colors.battleship_grey}
                            align="left"
                        />
                    </a>
                    <Space width={12} />
                    <ComponentText
                        text="•"
                        font={fonts.SFProDisplayMedium}
                        size={12}
                        color={Colors.battleship_grey}
                        align="left"
                    />
                    <Space width={12} />
                    <a href={links.Terms}>
                        <ComponentText
                            text="Privacy Policy"
                            font={fonts.SFProDisplayMedium}
                            size={12}
                            color={Colors.battleship_grey}
                            align="left"
                        />
                    </a>
                    <Space width={12} />
                    <ComponentText
                        text="•"
                        font={fonts.SFProDisplayMedium}
                        size={12}
                        color={Colors.battleship_grey}
                        align="left"
                    />
                    <Space width={12} />
                    <a href={links.PrivacyPolicy}>
                        <ComponentText
                            text="Login"
                            font={fonts.SFProDisplayMedium}
                            size={12}
                            color={Colors.battleship_grey}
                            align="left"
                        />
                    </a>
                </Row>
                <Space height={16} />
                <Row type={'flex'} justify={'center'}>
                    <ComponentText
                        text="© Copyright 2019 purchasefly. All Rights Reserved."
                        font={fonts.SFProDisplayMedium}
                        size={12}
                        color={Colors.cool_grey_three}
                        align="left"
                    />
                </Row>
                <Space height={20} />
            </Row>
        );
    }
}
