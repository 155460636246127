import React from 'react';
import './Documentation.css';
import { Banner } from './Banner';

import Space from '../Shared/ComponentUI/Spcae';
import { Footer } from '../Shared/Menus/Footer';
import { TopBarDocumentation } from '../Shared/Menus/TopBarDocumentation';
import { WelcomeContent } from './WelcomeContent';

class Documentation extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: 'var(--white-two)' }}>
                <div className={'documentation-top'} />
                <TopBarDocumentation />
                <Space height={90} />
                <Banner />
                <Space height={50} />
                <WelcomeContent />
                <Space height={120} />
                <Footer />
            </div>
        );
    }
}
export default Documentation;
