import React from 'react';
import './ChartsMetrics.css';
import { Banner } from './Banner';

import { TopBar } from '../Shared/Menus/TopBar';
import { ReadyToStartMobileBusiness } from '../Shared/ReadyToStartMobileBusiness';
import { BottomMenu } from '../Shared/Menus/BottomMenu';
import { Footer } from '../Shared/Menus/Footer';
import { FeaturesPurchaseFly } from '../Shared/FeaturesPurchaseFly';
import { SourceOfTruth } from './SourceOfTruth';
import Images from '../../images/images';
import { Features } from '../SubscriptionManagement/Features';
import { UncoverGrowth } from './UncoverGrowth';

class ChartsMetrics extends React.Component {
    render() {
        return (
            <div>
                <TopBar />

                <Banner />

                <SourceOfTruth />
                <Features contents={features} />
                <UncoverGrowth />
                <FeaturesPurchaseFly title={"There's more for you"} index={4} />
                <ReadyToStartMobileBusiness />

                <BottomMenu color="#fafafa" details={true} />

                <Footer />
            </div>
        );
    }
}
export default ChartsMetrics;

const features = [
    {
        image: Images.makeInformedDecisions,
        title: 'Make informed decisions',
        description:
            'Is a certain user non-paying, in trial or has active subscription? Forget about UserDefaults and Keychain. Apphud will keep user state up-to-date using Apple servers.',
        link: '',
        left: true,
        spans: [11, 13],
        space: 180,
        shadow: false,
    },
    {
        image: Images.increaseConversion,
        title: 'Increase conversion and engagement',
        description:
            'Securely validate App Store receipts. Prevent fraud transactions and do not unlock premium features for cheaters.',
        link: '',
        left: false,
        spans: [13, 11],
        space: 180,
        shadow: true,
    },
    {
        image: Images.flexibleCharts,
        title: 'Flexible charts the way you want',
        description:
            'Is a certain user non-paying, in trial or has active subscription? Forget about UserDefaults and Keychain. Apphud will keep user state up-to-date using Apple servers.',
        link: '',
        left: true,
        spans: [11, 13],
        space: 180,
        shadow: false,
    },
];
