import React from 'react';
import { Col, Row } from 'antd';
import { ComponentText } from '../Shared/ComponentUI/ComponentText';
import Space from '../Shared/ComponentUI/Spcae';
import Images from '../../images/images';
import { fonts } from '../../fonts/fonts';
import { Colors } from '../../css/Colors';
import { ComponentButton } from '../Shared/ComponentUI/ComponentButton';
import { paths } from '../Shared/Routes';
import './builtForDev.css';
import { CodeSnippet } from '../Shared/CodeSnippet/CodeSnippet';
import { getDecreasingSpan, getIncreasingSpan } from '../Shared/Col4';

const features = [
    {
        icon: Images.featureDeploy,
        title: 'Deploy Quickly',
        description: 'Purchasefly cuts deployment<br/>from months to hours with the<br/>help of our easy setup.',
    },
    {
        icon: Images.featureLessFragment,
        title: 'Less Fragmentation',
        description: 'We create a central source of<br/>truth for your subscribers<br/>across all app stores.',
    },
    {
        icon: Images.featureSegment,
        title: 'Segment Your Data',
        description: "Segment metrics like MRR,<br/>Revenue, and Active Subscribers<br/>to discover what's working.",
    },
];
export class BuiltForDev extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: 1400 };
    }
    componentDidMount() {
        this.setState({ screenWidth: window.innerWidth });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
        this.setState({ screenWidth: window.innerWidth });
    };

    render() {
        const { screenWidth } = this.state;
        return screenWidth < 700 ? this.prepareMobileView() : this.prepareView();
    }

    prepareView() {
        return (
            <div>
                {' '}
                <Row type="flex">
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                    <Col span={getIncreasingSpan(this.state.screenWidth, 16, 2)} style={{ backgroundColor: '#000000' }}>
                        <Space height={50} />
                        <ComponentText
                            text="Built With Developers in Mind"
                            align="center"
                            font={fonts.SFProDisplayBold}
                            size={50}
                            color={Colors.white}
                        />
                        <Space height={56} />
                        <a href={paths.Documentation}>
                            <ComponentButton
                                text={'View Documentation'}
                                height={50}
                                css={'deep-sky-blue-and-white'}
                                iconRight={'arrow-right-white'}
                            />
                        </a>
                        <Space height={56} />
                        <CodeSnippet />
                        {/*<img alt="" src={Images.featureBuiltForDev} style={{width: "75%"}}/>*/}
                        <Space height={60} />
                        <Row type={'flex'} justify={'center'}>
                            {features.map((e, i) => (
                                <ComponentPrepareItem
                                    key={i}
                                    image={e.icon}
                                    title={e.title}
                                    description={e.description}
                                />
                            ))}
                        </Row>
                        <Space height={80} />
                    </Col>
                    <Col span={getDecreasingSpan(this.state.screenWidth, 4, 1)} />
                </Row>
                <Space height={100} />
            </div>
        );
    }

    prepareMobileView() {
        return (
            <div style={{ backgroundColor: '#000000' }}>
                <Space height={50} />
                <div style={{ marginLeft: 40, marginRight: 40 }}>
                    <ComponentText
                        text="Built With Developers in Mind"
                        align="center"
                        font={fonts.SFProDisplayBold}
                        size={40}
                        color={Colors.white}
                    />
                </div>
                <Space height={56} />
                <a href={paths.Documentation}>
                    <ComponentButton
                        text={'View Documentation'}
                        height={40}
                        css={'deep-sky-blue-and-white-mobile'}
                        iconRight={'arrow-right-white'}
                    />
                </a>
                <Space height={56} />
                <CodeSnippet width="90%" />
                {/*<img alt="" src={Images.featureBuiltForDev} style={{width: "75%"}}/>*/}
                <Space height={20} />
                <Row type={'flex'} justify={'center'}>
                    {features.map((e, i) => (
                        <ComponentPrepareItemMobile
                            key={i}
                            image={e.icon}
                            title={e.title}
                            description={e.description}
                        />
                    ))}
                </Row>

                <Space height={100} />
            </div>
        );
    }
}
function ComponentPrepareItem({ image, title, description }) {
    return (
        <Row style={{ marginLeft: 50, marginRight: 50 }}>
            <img alt="" src={image} style={{ width: 65 }} />
            <Space height={16} />
            <ComponentText
                text={title}
                align="center"
                font={fonts.SFProDisplaySemiBold}
                size={25}
                color={Colors.white}
            />
            <Space height={16} />
            <ComponentText
                text={description}
                align="center"
                lineHeight={'22px'}
                font={fonts.SFProDisplayRegular}
                size={16}
                color={Colors.white}
            />
        </Row>
    );
}

function ComponentPrepareItemMobile({ image, title, description }) {
    return (
        <Row style={{ marginLeft: 50, marginRight: 50 }}>
            <img alt="" src={image} style={{ width: 65 }} />
            <Space height={16} />
            <ComponentText
                text={title}
                align="center"
                font={fonts.SFProDisplaySemiBold}
                size={25}
                color={Colors.white}
            />
            <Space height={16} />
            <ComponentText
                text={description}
                align="center"
                lineHeight={'22px'}
                font={fonts.SFProDisplayRegular}
                size={16}
                color={Colors.white}
            />
            <Space height={32} />
        </Row>
    );
}
