export function getSpan2(screenWidth) {
    if (screenWidth > 1200) return 2;
    else if (screenWidth > 1100) return 1;
    else if (screenWidth > 1000) return 0;
    else return 0;
}

export function getIncreasingSpan(screenWidth, start, offset) {
    if (screenWidth > 1200) return start;
    else if (screenWidth > 1100) return start + offset * 1;
    else if (screenWidth > 1000) return start + offset * 2;
    else return start + offset * 3;
}

export function getDecreasingSpan(screenWidth, start, offset) {
    if (screenWidth > 1200) return start;
    else if (screenWidth > 1100) return start - offset * 1;
    else if (screenWidth > 1000) return start - offset * 2;
    else return start - offset * 3;
}

export function getTopBarSpace(screenWidth) {
    if (screenWidth > 1200) return 12;
    else if (screenWidth > 1100) return 8;
    else if (screenWidth > 1000) return 4;
    else return 0;
}
